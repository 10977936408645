import { ReactComponent as FacebookLogo } from 'src/assets/icons/logo-facebook.svg';
import { ReactComponent as InstagramLogo } from 'src/assets/icons/logo-instagram.svg';
import { ReactComponent as LinkedinLogo } from 'src/assets/icons/logo-linkedin.svg';
import { ReactComponent as TwitterLogo } from 'src/assets/icons/logo-twitter.svg';
import { ReactComponent as YoutubeLogo } from 'src/assets/icons/logo-youtube.svg';

export const SOCIAL_LINKS = [
  {
    label: 'Facebook',
    logo: FacebookLogo,
    url: window.FACEBOOK_PAGE_URL,
  },
  {
    label: 'Twitter',
    logo: TwitterLogo,
    url: window.TWITTER_PAGE_URL,
  },
  {
    label: 'LinkedIn',
    logo: LinkedinLogo,
    url: window.LINKED_IN_PAGE_URL,
  },
  {
    label: 'Youtube',
    logo: YoutubeLogo,
    url: window.YOUTUBE_PAGE_URL,
  },
  {
    label: 'Instagram',
    logo: InstagramLogo,
    url: window.INSTAGRAM_PAGE_URL,
  },
].filter((item) => item.url);
