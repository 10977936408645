import { makeAutoObservable } from 'mobx';

class LocationScreenStore {
  isShown: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsShown(isShown: boolean) {
    this.isShown = isShown;
  }

  show() {
    this.setIsShown(true);
  }

  hide() {
    this.setIsShown(false);
  }

  toggle() {
    this.isShown = !this.isShown;
  }
}

export default new LocationScreenStore();
