import React from 'react';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';

import type { SectionMarkerProps } from './section-marker.props';

import './section-marker.scss';

const SectionMarker = ({ sectionNumber, position }: SectionMarkerProps) => {
  const getPixelPositionOffset = (offsetWidth: number, offsetHeight: number) => {
    return {
      x: -(offsetWidth / 2),
      y: -offsetHeight,
    };
  };

  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.MARKER_LAYER}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div className="section-marker">
        <span className="section-marker__label">{sectionNumber}</span>
      </div>
    </OverlayViewF>
  );
};

export default SectionMarker;
