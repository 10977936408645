import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import CemeteryLogo from 'src/components/common/cemetery-logo';
import ExternalLink from 'src/components/common/external-link';

import globalAppStore from 'src/stores/global-app-store';

import { LogoTextLocation } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { getContactLinks } from 'src/utils';
import type { CemeteryInfoProps } from './cemetery-info.props';

import './cemetery-info.scss';

const CemeteryInfo = observer(({ className }: CemeteryInfoProps) => {
  const { cemeteryInfo } = globalAppStore;
  const { getLocalization } = useLocalization();

  return (
    <div className={classnames('cemetery-info', className)}>
      <CemeteryLogo textLocation={LogoTextLocation.SIDE} isFooter />

      <ul className="cemetery-info__contacts" aria-label={getLocalization('Cemetery contacts')}>
        {getContactLinks(cemeteryInfo).map(({ link, label, icon: Icon, target }) => (
          <li key={label} className="cemetery-info__contacts-item">
            <ExternalLink
              className="cemetery-info__contacts-item-link"
              ariaLabel={label}
              href={link}
              target={target}
            >
              <Icon className="cemetery-info__contacts-item-icon" role="presentation" />
              <span className="cemetery-info__contacts-item-text">{label}</span>
            </ExternalLink>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default CemeteryInfo;
