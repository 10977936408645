import { mapApiToCemeteryInfo } from 'src/adapters';
import { doGet } from 'src/api/axios-config';
import { ApiRoute, SortDirection } from 'src/constants';
import type { IApiCemetery, IApiMausoleum, IApiSection, IFilter } from 'src/interfaces';

async function fetchCemeterySections(sortDirection: SortDirection) {
  try {
    const result = await doGet<IApiSection[]>(ApiRoute.CEMETERY_SECTIONS, {
      params: { sort: JSON.stringify({ no: sortDirection }) },
    });
    return result.data || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

async function fetchCemeteryMausoleums(sortDirection: SortDirection) {
  try {
    const result = await doGet<IApiMausoleum[]>(ApiRoute.CEMETERY_MAUSOLEUMS, {
      params: { sort: JSON.stringify({ name: sortDirection }) },
    });
    return result.data || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchCemeteryInfo() {
  try {
    const result = await doGet<IApiCemetery>(ApiRoute.CEMETERY);

    if (result.data) {
      const sectionsInfo = await fetchCemeterySections(SortDirection.ASC);
      const mausoleumsInfo = await fetchCemeteryMausoleums(SortDirection.ASC);
      return mapApiToCemeteryInfo(result.data, sectionsInfo, mausoleumsInfo);
    } else {
      return null;
    }
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchCemeteryFilters() {
  try {
    const result = await doGet<IFilter>(ApiRoute.CEMETERY_FILTERS);
    return result.data || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}
