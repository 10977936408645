export const formatPhoneToApi = (phone = '') => phone.replace(/\D/g, '');

export const formatApiToPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  const newPhone = formatPhoneToApi(phone);
  return newPhone ? `(${newPhone.slice(0, 3)}) ${newPhone.slice(3, 6)}-${newPhone.slice(6)}` : '';
};
