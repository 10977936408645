import { useEffect, useRef } from 'react';

const THRESHOLD = 80;
const SWIPE_SPEED = 1000;

interface UseSwipeProps {
  down?: () => void;
  left?: () => void;
  right?: () => void;
  up?: () => void;
  isListenerStopped?: boolean;
  ignoredNode?: Element | null;
}

export default function useSwipe({
  down,
  left,
  right,
  up,
  isListenerStopped = false,
  ignoredNode,
}: UseSwipeProps) {
  const touchCoordsRef = useRef({ touchStart: { x: 0, y: 0, time: Date.now() } });
  const handlersRef = useRef({ up, down, left, right });
  handlersRef.current = {
    up,
    left,
    down,
    right,
  };

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      if (ignoredNode?.contains(e.target as Element)) {
        touchCoordsRef.current.touchStart.time = 0;
        return;
      }

      touchCoordsRef.current.touchStart.x = e.targetTouches[0].clientX;
      touchCoordsRef.current.touchStart.y = e.targetTouches[0].clientY;
      touchCoordsRef.current.touchStart.time = Date.now();
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      const touchStartX = touchCoordsRef.current.touchStart.x;
      const touchStartY = touchCoordsRef.current.touchStart.y;
      const elapsedTime = Date.now() - touchCoordsRef.current.touchStart.time;

      if (elapsedTime > SWIPE_SPEED) {
        return;
      }

      const xDistance = touchStartX - touchEndX;
      const yDistance = touchStartY - touchEndY;

      if (Math.abs(xDistance) < THRESHOLD && Math.abs(yDistance) < THRESHOLD) {
        return;
      }

      if (Math.abs(xDistance) >= Math.abs(yDistance)) {
        xDistance > 0 ? handlersRef.current.right?.() : handlersRef.current.left?.();
      } else {
        yDistance > 0 ? handlersRef.current.down?.() : handlersRef.current.up?.();
      }
    };

    const addListeners = () => {
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchend', handleTouchEnd);
    };

    const removeListeners = () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };

    if (isListenerStopped) {
      return removeListeners();
    } else {
      addListeners();
    }

    return removeListeners;
  }, [ignoredNode, isListenerStopped]);
}
