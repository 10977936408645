import { getIsAddressFull } from './get-input-validators';

import { ErrorText } from 'src/constants';
import type { IAddress, IContactPersonFields, IContactPersonStringFields } from 'src/interfaces';

const getStringValueTextError = (field: IContactPersonStringFields, value: string) => {
  const trimmedValue = value.trim();

  if (!trimmedValue) {
    return ErrorText.FIELD_IS_REQUIRED;
  }

  switch (field) {
    case 'dateOfBirth':
      return ErrorText.DATE_ERROR;
    case 'email':
      return ErrorText.EMAIL_FORMAT_INVALID_ERROR;
    case 'firstName':
    case 'lastName':
      return ErrorText.NAME_FORMAT_INVALID_ERROR;
    case 'phone':
      return ErrorText.PHONE_NUMBER_ERROR;
    default:
      return '';
  }
};

const getAddressTextError = (value: IAddress) => {
  const isFullAddress = getIsAddressFull(value);

  if (isFullAddress) {
    return '';
  } else {
    return ErrorText.ADDRESS_INCOMPLETE;
  }
};

export const getTextError = (field: IContactPersonFields, value: string | IAddress | null) => {
  if (!value) {
    return ErrorText.FIELD_IS_REQUIRED;
  }

  if (field !== 'address' && typeof value === 'string') {
    return getStringValueTextError(field, value);
  }

  if (field === 'address' && typeof value === 'object') {
    return getAddressTextError(value);
  }

  return ErrorText.DEFAULT_INVALID;
};
