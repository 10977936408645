import React from 'react';

import useImagesListScroll from 'src/hooks/use-images-list-scroll';
import type { ILotImage } from 'src/interfaces';

const MIN_IMAGES_COUNT_TO_SHOW_CONTROLS = 2;

interface ImageSizes {
  height: number;
  width: number;
}

export default function useImageViewer(images: ILotImage[], initialIndex: number) {
  const [imageCurrentIndex, setImageCurrentIndex] = React.useState(initialIndex);
  const imageSrc = images[imageCurrentIndex].originImg;
  const thumbsTotalCount = images.length;
  const isControlsShouldBeShown = images.length >= MIN_IMAGES_COUNT_TO_SHOW_CONTROLS;
  const { elementRef, isDesktop, listRef } = useImagesListScroll(images, imageCurrentIndex);
  const [loadedImagesSizes, setLoadedImagesSizes] = React.useState<Record<string, ImageSizes>>({});

  const handlePrevBtnClick = () => {
    const index = imageCurrentIndex === 0 ? thumbsTotalCount - 1 : imageCurrentIndex - 1;
    setImageCurrentIndex(index);
  };

  const handleNextBtnClick = () => {
    const index = imageCurrentIndex === thumbsTotalCount - 1 ? 0 : imageCurrentIndex + 1;
    setImageCurrentIndex(index);
  };

  const handleImageLoad = React.useCallback(
    (obj: { naturalWidth: number; naturalHeight: number; src: string }) => {
      setLoadedImagesSizes((images) => ({
        ...images,
        [obj.src]: { height: obj.naturalHeight, width: obj.naturalWidth },
      }));
    },
    []
  );

  return {
    elementRef,
    handleImageLoad,
    handleNextBtnClick,
    handlePrevBtnClick,
    imageCurrentIndex,
    imageSrc,
    isControlsShouldBeShown,
    isDesktop,
    listRef,
    loadedImagesSizes,
    setImageCurrentIndex,
  };
}
