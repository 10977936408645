import {
  getIsAddressFull,
  getIsDateValid,
  getIsEmailValid,
  getIsNameValid,
  getIsPhoneValid,
} from 'src/utils';
import type { IAddress, IContactPersonFields, IContactPersonStringFields } from 'src/interfaces';

const checkIsPersonValidStringValue = (field: IContactPersonStringFields, value: string) => {
  const trimmedValue = value.trim();

  switch (field) {
    case 'addressString':
      return !!trimmedValue;
    case 'dateOfBirth':
      return getIsDateValid(trimmedValue);
    case 'email':
      return getIsEmailValid(trimmedValue);
    case 'firstName':
    case 'lastName':
      return getIsNameValid(trimmedValue);
    case 'phone':
      return getIsPhoneValid(trimmedValue);
    default:
      return true;
  }
};

export const checkIsPersonValidValue = (
  field: IContactPersonFields,
  value: string | IAddress | null
) => {
  if (field !== 'address' && typeof value === 'string') {
    return checkIsPersonValidStringValue(field, value);
  }

  if (field === 'address' && !!value && typeof value === 'object') {
    return getIsAddressFull(value);
  }

  return false;
};
