import { makeAutoObservable } from 'mobx';

import { PopupName } from 'src/constants';

type IPopups = Record<PopupName, PopupState>;

type PopupState = Record<string, any>;

class PopupStore {
  activePopup: PopupName | null = null;

  popups: IPopups = Object.values(PopupName).reduce(
    (popups, popupName) => ({
      ...popups,
      [popupName as PopupName]: this.getInitialPopupState(),
    }),
    {} as IPopups
  );

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hidePopup(popupName: PopupName) {
    this.activePopup = null;
    this.popups[popupName] = this.getInitialPopupState();
  }

  showPopup(popupName: PopupName, state: PopupState = {}) {
    this.activePopup = popupName;
    this.popups[popupName] = Object.assign({ isVisible: true }, state);
  }

  getPopupState(popupName: PopupName) {
    return this.popups[popupName];
  }

  getInitialPopupState() {
    return {
      isVisible: false,
    };
  }
}

export default new PopupStore();
