import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import filterStore from 'src/stores/filter-store';

import { AppRoute } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { getFiltersSearchParamsString } from 'src/utils';
import type { FindAnotherLotButtonProps } from './find-another-lot-button.props';

import './find-another-lot-button.scss';

const FindAnotherLotButton = observer(({ className }: FindAnotherLotButtonProps) => {
  const { getLocalization } = useLocalization();
  return (
    <Button
      className={classNames('find-another-lot-button', className)}
      icon={<SearchIcon />}
      href={`${AppRoute.MAP_VIEW_PAGE}?${getFiltersSearchParamsString(filterStore)}`}
      label={getLocalization('Find Another Lot')}
      size="normal"
      tag="link"
      target="_blank"
      theme="outlined"
    />
  );
});

export default FindAnotherLotButton;
