import React from 'react';

import CemeteryLogo from 'src/components/common/cemetery-logo';
import Footer from 'src/components/common/footer';
import Button from 'src/components/ui/button';

import { AppRoute, FooterTheme, LogoTextLocation } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';

import './not-found-page.scss';

const NotFoundPage = () => {
  const { getLocalization } = useLocalization();
  return (
    <div className="not-found-page scrollbar">
      <div className="not-found-page__wrapper">
        <CemeteryLogo textLocation={LogoTextLocation.BOTTOM} isColored />
        <div className="not-found-page__content">
          <h1 className="not-found-page__text">
            <span className="not-found-page__text-accent">404</span>
            {getLocalization('Page not found')}
          </h1>
          <Button
            className="not-found-page__button"
            href={AppRoute.MAIN_PAGE}
            label={getLocalization('Back to Home Page')}
            size="oversize"
            tag="link"
            theme="outlined"
          />
        </div>
      </div>
      <Footer theme={FooterTheme.DARK} />
    </div>
  );
};

export default NotFoundPage;
