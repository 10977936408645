import React, { createContext, useContext, useRef } from 'react';

import type { HeaderContextProps, HeaderProviderProps } from './header-context.props';

const DEFAULT_HEADER_HEIGHT = 82;

export const HeaderContext = createContext<HeaderContextProps>({ headerRef: null });

const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const headerRef = useRef<HTMLHeadElement | null>(null);

  return <HeaderContext.Provider value={{ headerRef }}>{children}</HeaderContext.Provider>;
};

const useHeader = () => {
  const { headerRef } = useContext(HeaderContext);
  const headerHeight = headerRef?.current?.getBoundingClientRect().height ?? DEFAULT_HEADER_HEIGHT;

  return { headerRef, headerHeight };
};

export { HeaderProvider, useHeader };
