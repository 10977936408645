import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import {
  DEFAULT_LOCALE,
  IS_WITH_LOCALIZATION,
  LOCALIZATION_WILDCARD,
  LOCALIZATION_WILDCARDS_LIST,
  PUBLIC_URL,
} from 'src/constants';
import type {
  LocalizationContextProps,
  LocalizationProviderProps,
} from './localization-context.props';

interface ILocalization {
  [key: string]: string;
}

const LocalizationContext = createContext<LocalizationContextProps>({
  locale: DEFAULT_LOCALE,
  isLocalizationLoading: false,
  getLocalization: (text: string) => text,
  setLocale: (_locale: string) => {},
});

const filterStringValues = (obj: Record<string, any>) => {
  return Object.keys(obj)
    .filter((key) => typeof obj[key] === 'string')
    .reduce<Record<string, string>>((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
};

export const LocalizationProvider = ({ children }: LocalizationProviderProps) => {
  const [isLocalizationLoading, setIsLocalizationLoading] = useState<boolean>(false);
  const [locale, setLocale] = useState<TLocalesType>(DEFAULT_LOCALE);
  const [localization, setLocalization] = useState<ILocalization>({});

  useEffect(() => {
    const fetchLocalizationData = async () => {
      setIsLocalizationLoading(true);
      try {
        const response = await axios.get<ILocalization>(
          `${PUBLIC_URL}/localization/${locale}.json`
        );
        setLocalization(filterStringValues(response.data));
      } catch (e) {
        console.log(e);
        setLocalization({});
      } finally {
        setIsLocalizationLoading(false);
      }
    };

    if (IS_WITH_LOCALIZATION) {
      void fetchLocalizationData();
    }
  }, [locale]);

  const getLocalization = useCallback(
    (
      text: string,
      wildCardValue?: string | undefined,
      secondWildCardValue?: string | undefined
    ): string => {
      let translatedText = text;

      const keys = Object.keys(localization);
      if (keys.length) {
        const lowerCaseText = text.toLowerCase();
        const translatedKey = keys.find((k) => k.toLowerCase() === lowerCaseText);

        if (translatedKey && localization[translatedKey]) {
          translatedText = localization[translatedKey];
        }
      }

      return translatedText.includes(LOCALIZATION_WILDCARD)
        ? replaceWildCards(translatedText, wildCardValue, secondWildCardValue)
        : translatedText;
    },
    [localization]
  );

  return (
    <LocalizationContext.Provider
      value={{ isLocalizationLoading, getLocalization, setLocale, locale }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

function replaceWildCards(
  text: string,
  wildCardValue: string | undefined,
  secondWildCardValue: string | undefined
) {
  const wildCardsInTextCount = LOCALIZATION_WILDCARDS_LIST.filter((char) =>
    text.includes(char)
  ).length;
  const checksIsNotUndefined = <T extends any>(value: T | undefined): value is T =>
    typeof value !== 'undefined';
  const wildCardsValues = [wildCardValue, secondWildCardValue].filter(checksIsNotUndefined);

  if (wildCardsInTextCount !== wildCardsValues.length) {
    console.error(
      `Localization error for "${text}": the number of special characters (${wildCardsInTextCount}) does not match the number of replacement values (${wildCardsValues.length})`
    );
    return text;
  }

  wildCardsValues.forEach((value, idx) => {
    text = text.replaceAll(LOCALIZATION_WILDCARDS_LIST[idx], value);
  });

  return text;
}

export const useLocalization = () => useContext(LocalizationContext);
