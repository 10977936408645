import { makeAutoObservable } from 'mobx';

import { fetchCemeteryFilters, fetchCemeteryInfo } from 'src/api';
import { CONFIG_REQUIRED_PARAMS_MAPPED, THEME_REQUIRED_PROPERTY } from 'src/constants';
import type { ICemetery, IRange } from 'src/interfaces';

class GlobalAppStore {
  async init() {
    this.setLoading(true);

    await Promise.all([
      fetchCemeteryInfo().then(this.setCemetery),
      fetchCemeteryFilters().then((data) => data?.price && this.setPriceRange(data.price)),
    ]);

    this.setLoading(false);
  }

  cemetery: ICemetery | null = null;
  isLoading = false;
  priceRange: IRange = { from: 0, to: 0 };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    void this.init();
  }

  setCemetery(cemetery: ICemetery | null) {
    this.cemetery = cemetery;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPriceRange(price: IRange) {
    this.priceRange = price;
  }

  get cemeteryInfo() {
    const { CEMETERY_EMAIL, CEMETERY_NAME, CEMETERY_PHONE } = window;
    const email = CEMETERY_EMAIL || (this.cemetery?.email ?? '');
    const name = CEMETERY_NAME || (this.cemetery?.name ?? '');
    const phone = CEMETERY_PHONE || (this.cemetery?.phone ?? '');
    return { email, name, phone };
  }

  get missingConfigVars() {
    return CONFIG_REQUIRED_PARAMS_MAPPED.filter((item) =>
      Array.isArray(item.value) ? !item.value.length : !item.value
    );
  }

  get isLoadedTheme() {
    return !!window.getComputedStyle(document.body).getPropertyValue(THEME_REQUIRED_PROPERTY);
  }

  get isConfigCorrect() {
    return this.missingConfigVars.length === 0;
  }

  get isConfigAndThemeLoaded() {
    return this.isConfigCorrect && this.isLoadedTheme;
  }
}

export default new GlobalAppStore();
