import { GeometryShape } from 'src/constants';
import type { ICoords, IGeometry, ILatLngCoords } from 'src/interfaces';

export const getCoords = (coordinates: ICoords): ILatLngCoords => ({
  lat: coordinates[1],
  lng: coordinates[0],
});

export const getPathsCoords = (geometry?: IGeometry): (ILatLngCoords | ILatLngCoords[])[] => {
  const paths: ILatLngCoords[][] = [];
  if (!geometry?.coordinates) {
    return paths;
  }

  switch (geometry?.type) {
    case GeometryShape.POLYGON:
      return geometry.coordinates[0].map((item) => getCoords(item));

    case GeometryShape.MULTI_POLYGON:
      geometry.coordinates.forEach((item) => {
        const newPath = item[0].map((item) => getCoords(item));
        paths.push(newPath);
      });
      return paths;

    default:
      return paths;
  }
};
