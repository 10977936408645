import React from 'react';
import { observer } from 'mobx-react-lite';

import Popup from './popup';

import popupStore from 'src/stores/popup-store';

const Popups = observer(() => {
  const { activePopup } = popupStore;

  return activePopup ? <Popup popupName={activePopup} /> : null;
});

export default Popups;
