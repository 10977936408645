export const CYRILLIC_SYMBOLS_REG_EXP = /[а-яё]/gi;

export const InputMinLength = {
  NAME: 1,
  TEXT: 1,
};

export const InputMaxLength = {
  APT: 10,
  COMMENT: 256,
  EMAIL: 254,
  NAME: 30,
  NOTES: 1000,
};
