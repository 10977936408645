import type { IAddress } from 'src/interfaces';

export const getAddress = (address?: Partial<IAddress>, apt?: string) => {
  if (!address) {
    return '';
  }

  const { building, city, state, street, zip } = address;
  const room = apt?.trim() ? `#${apt.trim()}` : '';
  const addressTemplate = [[building, street], [room], [city], [state, zip]];
  const joinParts = (parts: (string | undefined)[], separator = ' ') =>
    parts.filter(Boolean).join(separator);

  return joinParts(
    addressTemplate.map((addressPart) => joinParts(addressPart)),
    ', '
  );
};
