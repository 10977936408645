import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { CopyToClipboardButtonProps } from './copy-to-clipboard-button.props';

import './copy-to-clipboard-button.scss';

const CopyToClipboardButton = ({ children, url }: CopyToClipboardButtonProps) => {
  const { getLocalization } = useLocalization();
  const onCopy = () => {
    popupStore.showPopup(PopupName.WARN, {
      title: getLocalization('Copied'),
      text: getLocalization('Page link has been copied to the clipboard'),
    });
  };

  return (
    <CopyToClipboard text={url} onCopy={onCopy}>
      <button
        className="copy-to-clipboard-button__support-button"
        aria-label={getLocalization('Copy URL')}
      >
        {children}
      </button>
    </CopyToClipboard>
  );
};

export default CopyToClipboardButton;
