import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useLocalization } from 'src/contexts/localization-context';

import './reservation-header.scss';

const ReservationHeader = observer(({ isConfirmationStage }: { isConfirmationStage: boolean }) => {
  const { getLocalization } = useLocalization();

  const reservationHeaderClasses = classNames(
    'reservation-header',
    isConfirmationStage && 'reservation-header_confirmation'
  );

  return (
    <div className={reservationHeaderClasses}>
      <h1 className="reservation-header__title">
        {isConfirmationStage
          ? getLocalization('Confirm the Reservation')
          : getLocalization('Reservation Details')}
      </h1>

      {isConfirmationStage ? (
        <p className="reservation-header__text">
          {getLocalization(
            'Please check the correctness of the entered data and confirm the Reservation'
          )}
        </p>
      ) : (
        <p className="reservation-header__description">
          {getLocalization(
            'Please note that the cemetery requires one business day to review and confirm your reservation. You will receive a final confirmation email and payment instructions in one business day after your request is submitted.'
          )}
        </p>
      )}
    </div>
  );
});

export default ReservationHeader;
