export const Regex = {
  NAME_VALIDATE: /(^[a-zA-Z.'"`’\s-]+$)/,
  EMAIL_VALIDATE:
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
  PHONE: /^\(([0-9]{3})\) ([0-9]{3})-([0-9]{2})([0-9]{2})$/,
  DIGIT: /^[0-9]*$/,
  DATE: /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/,
  YOUTUBE:
    // eslint-disable-next-line
    /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\?&\'>]+)/,
};
