import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import RangeSelect from 'src/components/ui/range-select';
import Select from 'src/components/ui/select';

import filterStore from 'src/stores/filter-store';
import globalAppStore from 'src/stores/global-app-store';

import { useLocalization } from 'src/contexts/localization-context';
import { addAllOption, getFiltersSearchParamsObject, getSectionName } from 'src/utils';
import type { IGetLocalization, IOption } from 'src/interfaces';
import type { MapFiltersProps } from './map-filters.props';

import './map-filters.scss';

const MapFilters = observer(({ isDesktop, onClose, onMapReset }: MapFiltersProps) => {
  const [, setSearchParams] = useSearchParams();
  const { getLocalization } = useLocalization();
  const { from: minPriceValue, to: maxPriceValue } = globalAppStore.priceRange;
  const {
    isLoading,
    location,
    mausoleumsOptions,
    priceRange,
    rights,
    rightsOptions,
    sectionsOptions,
    sort,
    type,
    typesOptions,
    clearFilters,
    handleChangeLocation,
    handleChangePriceRange,
    handleChangeRights,
    handleChangeType,
  } = filterStore;

  const locationOptions = useMemo(
    () =>
      getLocalizationOptions(
        addAllOption([
          ...sectionsOptions.map((option) => ({
            ...option,
            value: getSectionName(option.value, getLocalization),
          })),
          ...mausoleumsOptions,
        ]),
        getLocalization
      ),
    [getLocalization, mausoleumsOptions, sectionsOptions]
  );

  const localizedTypesOptions = useMemo(
    () => getLocalizationOptions(typesOptions, getLocalization),
    [getLocalization, typesOptions]
  );

  const localizedRightsOptions = useMemo(
    () => getLocalizationOptions(rightsOptions, getLocalization),
    [getLocalization, rightsOptions]
  );

  const handleClearButtonClick = useCallback(() => {
    clearFilters();
    onMapReset?.();
  }, [clearFilters, onMapReset]);

  useEffect(() => {
    if (!isLoading) {
      setSearchParams(getFiltersSearchParamsObject({ location, priceRange, rights, sort, type }));
    }
  }, [isLoading, location, priceRange, rights, setSearchParams, sort, type]);

  return (
    <section className="map-filters" aria-label={getLocalization('Lots filters')}>
      <div className="map-filters__wrapper">
        <div className="map-filters__item">
          <span className="map-filters__item-label" id="map-filters-location">
            {getLocalization('Location')}:
          </span>
          <Select
            className="map-filters__select"
            desktopSize="small"
            isDisabled={locationOptions.length < 2}
            items={locationOptions}
            labelledBy="map-filters-location"
            size="regular"
            value={location || (locationOptions.length === 1 && locationOptions[0].id) || ''}
            onChange={handleChangeLocation}
          />
        </div>

        <div className="map-filters__item">
          <span className="map-filters__item-label" id="map-filters-type">
            {getLocalization('Lot Type')}:
          </span>
          <Select
            className="map-filters__select"
            desktopSize="small"
            isDisabled={typesOptions.length < 2}
            items={localizedTypesOptions}
            labelledBy="map-filters-type"
            size="regular"
            value={type || (typesOptions.length === 1 && typesOptions[0].id) || ''}
            onChange={handleChangeType}
          />
        </div>

        <div className="map-filters__item">
          <span className="map-filters__item-label" id="map-filters-rights">
            {getLocalization('Details')}:
          </span>
          <div className="map-filters__mobile-two-columns">
            <Select
              className="map-filters__select"
              desktopSize="small"
              isDisabled={!type || rightsOptions.length < 2}
              items={localizedRightsOptions}
              label={rights || rightsOptions.length === 1 ? '' : getLocalization('Rights')}
              labelledBy="map-filters-rights"
              size="regular"
              value={rights || (rightsOptions.length === 1 && rightsOptions[0].id) || ''}
              onChange={handleChangeRights}
            />
            <RangeSelect
              className="map-filters__select"
              desktopSize="small"
              dropdownClassName={classNames(
                'map-filters__range-dropdown',
                !isDesktop && 'map-filters__range-dropdown_mobile'
              )}
              label={getLocalization('Price')}
              maxValue={maxPriceValue}
              minValue={minPriceValue}
              range={priceRange}
              size="regular"
              handleChangeValues={handleChangePriceRange}
            />
          </div>
        </div>

        <div className="map-filters__mobile-two-columns">
          {onClose && (
            <Button
              className="map-filters__apply-button"
              desktopSize="flattened"
              label={getLocalization('Apply')}
              size="normal"
              theme="filled"
              onClick={onClose}
            />
          )}

          <Button
            className="map-filters__clear-button"
            desktopSize="flattened"
            label={getLocalization('Clear')}
            size="normal"
            theme="outlined"
            onClick={handleClearButtonClick}
          />
        </div>
      </div>
    </section>
  );
});

function getLocalizationOptions(options: IOption[], getLocalization: IGetLocalization) {
  return options.map(({ id, value }) => ({
    id,
    value: getLocalization(value),
  }));
}

export default MapFilters;
