import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/check-circle.svg';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { ICommonPopupProps } from 'src/interfaces';

interface PopupSuccessRequestText {
  text: string;
  subText?: string;
  description?: string;
}

const ContactsText: PopupSuccessRequestText = {
  text: 'We will contact you in 1 business day.',
};

const ReservationText: PopupSuccessRequestText = {
  text: 'Reserve request sent.',
  subText: 'All details will be forwarded to your email.',
  description:
    'Please note that the cemetery requires one business day to review and confirm your reservation. You will receive a final confirmation email and payment instructions in one business day after your request is submitted.',
};

const PopupSuccessRequest = observer(({ handleCloseButtonClick }: ICommonPopupProps) => {
  const { isReservation } = popupStore.getPopupState(PopupName.SUCCESS_REQUEST);
  const { text, subText, description } = isReservation ? ReservationText : ContactsText;
  const { getLocalization } = useLocalization();

  return (
    <>
      <CheckCircleIcon className="popup__icon" />

      <div className="popup__main">
        <div className="popup__header">
          <p className="popup__title">{getLocalization('Thank You!')}</p>

          {subText ? (
            <div className="popup__text-wrapper">
              <p className="popup__text">{getLocalization(text)}</p>
              <p className="popup__text">{getLocalization(subText)}</p>
            </div>
          ) : (
            <p className="popup__text">{getLocalization(text)}</p>
          )}
        </div>

        {description && <p className="popup__description">{getLocalization(description)}</p>}
      </div>

      <Button
        className="popup__button"
        label={getLocalization('Back to Lot Selector')}
        onClick={handleCloseButtonClick}
        size="oversize"
        theme="filled"
      />
    </>
  );
});

export default PopupSuccessRequest;
