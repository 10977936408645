import React from 'react';
import classNames from 'classnames';

import type { DropdownProps } from './dropdown.props';

import './dropdown.scss';

const Dropdown = ({ children, className, id }: DropdownProps) => {
  return (
    <div className={classNames('dropdown', className)} id={id} role="menu">
      {children}
    </div>
  );
};

export default React.memo(Dropdown);
