import React, { useCallback, useEffect, useState } from 'react';
import { MarkerF } from '@react-google-maps/api';

import { MarkerType } from 'src/constants';
import { getMarkerIcon, getMarkerLabel } from 'src/utils';
import type { MapMarkerProps } from './map-marker.props';

const MapMarker = ({
  label,
  clusterer,
  position,
  type = MarkerType.DOT,
  onClick,
}: MapMarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const isClickable = typeof onClick === 'function';
  const icon = getMarkerIcon(type);

  useEffect(() => {
    const newIcon = getMarkerIcon(type);
    marker?.setIcon(newIcon);
  }, [type, marker]);

  const handleMarkerLoad = useCallback((loadedMarker: google.maps.Marker) => {
    setMarker(loadedMarker);
  }, []);

  const handleMouseOver = useCallback(() => {
    if (!isClickable || type !== MarkerType.DOT) {
      return;
    }

    const newIcon = getMarkerIcon(MarkerType.BIG_DOT);

    marker?.setIcon(newIcon);
  }, [isClickable, type, marker]);

  const handleMouseOut = useCallback(() => {
    if (!isClickable || type !== MarkerType.DOT) {
      return;
    }

    const newIcon = getMarkerIcon(MarkerType.DOT);

    marker?.setIcon(newIcon);
  }, [isClickable, type, marker]);

  return (
    <MarkerF
      clusterer={clusterer}
      clickable={isClickable}
      icon={icon}
      label={label ? getMarkerLabel(label) : ''}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
      position={position}
      onLoad={handleMarkerLoad}
    />
  );
};

export default MapMarker;
