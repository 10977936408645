import { Fragment } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { DESCRIPTION_LABEL } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { DetailsSectionProps } from './details-section.props';

import './details-section.scss';

const DetailsSection = observer(({ data, title }: DetailsSectionProps) => {
  const { getLocalization } = useLocalization();
  return (
    <section className="details-section" aria-label={title}>
      <h2 className="details-section__title">{title}</h2>

      <dl className="details-section__text-wrapper">
        {data.map(({ label, value }) => (
          <Fragment key={label}>
            <dt className="details-section__label">{getLocalization(label)}:</dt>
            <dd
              className={classNames(
                'details-section__value',
                label === DESCRIPTION_LABEL && 'details-section__value_description'
              )}
            >
              {getLocalization(value)}
            </dd>
          </Fragment>
        ))}
      </dl>
    </section>
  );
});

export default DetailsSection;
