import { useEffect, useRef, useState } from 'react';

export default function useIntersect(disconnectParam: boolean) {
  const [entry, setEntry] = useState<IntersectionObserverEntry | {}>({});
  const [node, setNode] = useState<Element | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => () => setNode(null), []);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([newEntry]) => {
        setEntry(newEntry);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.9,
      }
    );

    const { current: currentObserver } = observer;
    if (node) {
      return disconnectParam ? currentObserver.disconnect() : currentObserver.observe(node);
    } else if (disconnectParam) {
      currentObserver.disconnect();
    }

    return () => currentObserver.disconnect();
  }, [node, disconnectParam]);

  return [setNode, entry] as const;
}
