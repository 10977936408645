import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CremationPlotIcon } from 'src/assets/icons/cremation-plot.svg';
import { ReactComponent as CryptIcon } from 'src/assets/icons/crypt.svg';
import { ReactComponent as NicheIcon } from 'src/assets/icons/niche.svg';
import { ReactComponent as PlotIcon } from 'src/assets/icons/plot.svg';

import { LotType, LotTypeShown, ServiceType, ServiceTypeValue } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { getLotTypeFilterUrl } from 'src/utils';
import type { BurialTypeProps } from './burial-type.props';

import './burial-type.scss';

const BurialTypeIcon = {
  [LotType.cremation_plot]: CremationPlotIcon,
  [LotType.crypt]: CryptIcon,
  [LotType.niche]: NicheIcon,
  [LotType.plot]: PlotIcon,
};

const BurialType = ({ type }: BurialTypeProps) => {
  const { getLocalization } = useLocalization();
  const Icon = BurialTypeIcon[type];
  const serviceType =
    type === LotType.niche || type === LotType.crypt
      ? ServiceType.MAUSOLEUM
      : ServiceType.IN_GROUND;

  return (
    <Link className="burial-type" to={getLotTypeFilterUrl(type)}>
      <Icon className="burial-type__icon" />
      <span className="burial-type__info">
        <span className="burial-type__category">
          {getLocalization(ServiceTypeValue[serviceType])}
        </span>
        <span className="burial-type__name">{getLocalization(LotTypeShown[type])}</span>
      </span>
    </Link>
  );
};

export default BurialType;
