import { Route } from 'react-router-dom';

import LotLayout from 'src/layouts/lot-layout';
import LotPage from 'src/pages/lot-page';
import ReservationPage from 'src/pages/reservation-page';

import { AppRoute } from 'src/constants';

const LOT_ROUTES = [
  { path: AppRoute.LOT_PAGE, component: LotPage, isLotPage: true },
  { path: `${AppRoute.RESERVATION_PAGE}/:id`, component: ReservationPage },
];

export function LotPageRoutes() {
  return LOT_ROUTES.map(({ path, component: Component, ...props }) => (
    <Route key={path} path={path} element={<LotLayout children={<Component />} {...props} />} />
  ));
}
