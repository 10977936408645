export const callNativeSharing = async (shareData: ShareData) => {
  if (navigator.share) {
    try {
      await navigator.share(shareData);
      return true;
    } catch (error: any) {
      if (error.toString().includes('AbortError')) {
        return true;
      }
    }
  }
  return false;
};
