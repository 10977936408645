import React from 'react';
import classNames from 'classnames';

import { ReactComponent as EnterIcon } from 'src/assets/icons/enter.svg';

import { useLocalization } from 'src/contexts/localization-context';
import type { SkipLinkProps } from './skip-link.props';

import './skip-link.scss';

function SkipLink({
  className,
  bottom = 'auto',
  href,
  label = 'Skip',
  left = '0px',
  position = 'absolute',
  top = '0px',
  withHint = true,
}: SkipLinkProps) {
  const { getLocalization } = useLocalization();
  const mainText = getLocalization(label);

  return (
    <a
      className={classNames('skip-link', className)}
      aria-label={mainText}
      href={href}
      style={{ bottom, left, position, top }}
    >
      <span className="skip-link__wrapper">
        <span className="skip-link__text  skip-link__text_main">{mainText}</span>
        {withHint && (
          <span className="skip-link__text  skip-link__text_additional">
            {getLocalization('Press Enter')} <EnterIcon className="skip-link__text-icon" />
          </span>
        )}
      </span>
    </a>
  );
}

export default SkipLink;
