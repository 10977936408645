import React from 'react';
import classNames from 'classnames';

import { useLocalization } from 'src/contexts/localization-context';
import type { TextErrorProps } from './text-error.props';

import './text-error.scss';

function TextError({ className, textError }: TextErrorProps) {
  const { getLocalization } = useLocalization();
  const classes = classNames('text-error', className);

  return <span className={classes}>{getLocalization(textError)}</span>;
}

export default TextError;
