import React, { SyntheticEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useLocalization } from 'src/contexts/localization-context';
import type { ButtonProps } from './icon-button.props';

import './icon-button.scss';

const IconButton = ({
  ariaLabel,
  className,
  href = '',
  icon,
  isDisabled,
  onClick,
  size,
  tag = 'button',
  target,
  theme: buttonTheme,
  type = 'button',
  ...props
}: ButtonProps) => {
  const { getLocalization } = useLocalization();
  const buttonClasses = classNames(
    'icon-button',
    buttonTheme && `icon-button_${buttonTheme}`,
    isDisabled && 'icon-button_disabled',
    size && `icon-button_size_${size}`,
    tag === 'button' || 'icon-button_reset-link',
    className
  );
  const localizedAriaLabel = getLocalization(ariaLabel);

  const handleClick = useCallback(
    (evt: SyntheticEvent) => {
      evt.stopPropagation();
      evt.preventDefault();

      if (typeof onClick === 'function') {
        onClick(evt);
      }
    },
    [onClick]
  );

  switch (tag) {
    case 'button':
      return (
        <button
          aria-label={localizedAriaLabel}
          className={buttonClasses}
          disabled={isDisabled}
          onClick={handleClick}
          type={type}
          {...props}
        >
          {icon}
        </button>
      );
    case 'link':
      return (
        <Link
          aria-label={
            target === '_blank'
              ? `${localizedAriaLabel} (${getLocalization('opens in new window')})`
              : localizedAriaLabel
          }
          className={buttonClasses}
          onClick={handleClick}
          target={target || '_self'}
          to={href}
          {...props}
        >
          {icon}
        </Link>
      );
    case 'a':
      return (
        <a
          aria-label={
            target === '_blank'
              ? `${localizedAriaLabel} (${getLocalization('opens in new window')})`
              : localizedAriaLabel
          }
          className={buttonClasses}
          href={href}
          onClick={handleClick}
          rel="noopener noreferrer"
          target={target || '_blank'}
          {...props}
        >
          {icon}
        </a>
      );
    default:
      return null;
  }
};

export default IconButton;
