import { LotType } from './lot';

export enum RightsType {
  COUCH = 'couch',
  DCOUCH = 'dcouch',
  DOUBLE = 'double',
  EIGHT = 'eight',
  ELEVEN = 'eleven',
  END_TO_END = 'end_to_end',
  END_TO_END_TO_END = 'end_to_end_to_end',
  FOUR = 'four',
  FIVE = 'five',
  NICHE_GLASS_FRONT = 'niche_glass_front',
  NINE = 'nine',
  OVERSIZE_SINGLE = 'oversize_single',
  OVERSIZE_END_TO_END = 'oversize_end_to_end',
  SEVEN = 'seven',
  SIDE_BY_SIDE = 'side_by_side',
  SINGLE = 'single',
  SIX = 'six',
  SMALL = 'small',
  SPECIAL_SINGLE = 'special_single',
  TEN = 'ten',
  TRIPLE = 'triple',
  TWELVE = 'twelve',
}

const getRightsList = (windowList: TRightType[] | undefined, defaultList: RightsType[]) => {
  if (Array.isArray(windowList)) {
    // noinspection SuspiciousTypeOfGuard
    return windowList.filter((item) => !!item && typeof item === 'string');
  }
  return defaultList;
};

export const Rights = {
  [RightsType.COUCH]: 'Couch',
  [RightsType.DCOUCH]: 'Dcouch',
  [RightsType.DOUBLE]: 'Double',
  [RightsType.EIGHT]: 'Eight',
  [RightsType.ELEVEN]: 'Eleven',
  [RightsType.END_TO_END]: 'End to End',
  [RightsType.END_TO_END_TO_END]: 'End to End to End',
  [RightsType.FOUR]: 'Four',
  [RightsType.FIVE]: 'Five',
  [RightsType.NICHE_GLASS_FRONT]: 'Glass Front Niche',
  [RightsType.NINE]: 'Nine',
  [RightsType.OVERSIZE_END_TO_END]: 'Oversize End to End',
  [RightsType.OVERSIZE_SINGLE]: 'Oversize Single',
  [RightsType.SEVEN]: 'Seven',
  [RightsType.SIDE_BY_SIDE]: 'Side by Side',
  [RightsType.SINGLE]: 'Single',
  [RightsType.SIX]: 'Six',
  [RightsType.SMALL]: 'Small',
  [RightsType.SPECIAL_SINGLE]: 'Special Single',
  [RightsType.TEN]: 'Ten',
  [RightsType.TRIPLE]: 'Triple',
  [RightsType.TWELVE]: 'Twelve',
};

const PlotRightsList = [
  RightsType.SINGLE,
  RightsType.DOUBLE,
  RightsType.TRIPLE,
  RightsType.FOUR,
  RightsType.FIVE,
  RightsType.SIX,
  RightsType.SEVEN,
  RightsType.EIGHT,
  RightsType.NINE,
  RightsType.TEN,
  RightsType.ELEVEN,
  RightsType.TWELVE,
];

const NicheRightsList = [
  RightsType.SINGLE,
  RightsType.SIDE_BY_SIDE,
  RightsType.SMALL,
  RightsType.SPECIAL_SINGLE,
  RightsType.END_TO_END_TO_END,
  RightsType.COUCH,
  RightsType.DCOUCH,
  RightsType.END_TO_END,
  RightsType.NICHE_GLASS_FRONT,
];

const CryptRightsList = [
  RightsType.SINGLE,
  RightsType.SIDE_BY_SIDE,
  RightsType.END_TO_END,
  RightsType.OVERSIZE_SINGLE,
  RightsType.OVERSIZE_END_TO_END,
  RightsType.SMALL,
  RightsType.SPECIAL_SINGLE,
  RightsType.END_TO_END_TO_END,
  RightsType.COUCH,
  RightsType.DCOUCH,
];

export const RightsListByLotType = {
  [LotType.plot]: getRightsList(window.PLOT_RIGHTS, PlotRightsList),
  [LotType.cremation_plot]: getRightsList(window.CREMATION_PLOT_RIGHTS, PlotRightsList),
  [LotType.niche]: getRightsList(window.NICHE_RIGHTS, NicheRightsList),
  [LotType.crypt]: getRightsList(window.CRYPT_RIGHTS, CryptRightsList),
};
