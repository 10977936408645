import { getLotUrl } from './get-lot-details';

import { APP_CURRENCY, LotTypeShown } from 'src/constants';
import type { ICemetery, ILot, ILotImage } from 'src/interfaces';

export const getDataStructure = (lot: ILot, organization: ICemetery) => {
  const { description } = organization;

  const lotImages = lot.media
    .filter((item) => 'img' in item && (item as ILotImage).img)
    .map((photo) => (photo as ILotImage).img);

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: `${LotTypeShown[lot.type]} ${lot.number}`,
    image: lotImages,
    description: lot.description || description,
    offers: {
      '@type': 'Offer',
      url: getLotUrl(lot.id, true),
      priceCurrency: APP_CURRENCY,
      price: lot.price.toString(),
      availability: 'https://schema.org/InStock',
    },
  };

  return JSON.stringify(data);
};
