import { mapContactPersonToApi } from './map-contact-person-to-api';

import type { IApiLotReservationPayload, IContactPersonFilled } from 'src/interfaces';

export const mapLotToApi = ({
  deedOwner,
  lotId,
  notes,
  purchaser,
}: {
  deedOwner: IContactPersonFilled;
  lotId: string;
  notes: string;
  purchaser: IContactPersonFilled;
}): IApiLotReservationPayload => ({
  cemetery: window.CEMETERY_ID,
  deed_owner: mapContactPersonToApi(deedOwner),
  lot: lotId,
  ...(notes && { notes }),
  purchaser: mapContactPersonToApi(purchaser),
});
