import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { GTM_ID } from 'src/constants';

export default function useGaTracker() {
  useEffect(() => {
    if (GTM_ID) {
      if (!ReactGA.isInitialized) {
        ReactGA.initialize(GTM_ID);
      }
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, []);
}
