export const ErrorText = {
  ADDRESS_INCOMPLETE: 'Address misses street name',
  DATE_ERROR: 'This is not a valid date',
  DEFAULT: 'Something went wrong. Please try again later',
  DEFAULT_INVALID: 'This is not a valid value',
  EMAIL_FORMAT_INVALID_ERROR: 'This is not a valid email address',
  FIELD_IS_REQUIRED: 'This field is required',
  NAME_FORMAT_INVALID_ERROR: 'This is not a valid name',
  REQUEST_FAILURE: 'Request failed with an error',
  PHONE_NUMBER_ERROR: 'This is not a valid phone number',
};
