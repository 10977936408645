import React from 'react';

import Button from 'src/components/ui/button';
import ShareButton from './components/share-button';

import popupStore from 'src/stores/popup-store';

import { PopupName, SHARE_BUTTONS } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { PopupShareProps } from './popup-share.props';

const PopupShare = ({ handleCloseButtonClick }: PopupShareProps) => {
  const { title, url } = popupStore.getPopupState(PopupName.SHARE);
  const { getLocalization } = useLocalization();

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{getLocalization('Share Lot')}</p>
      </div>
      <div className="popup__items-wrapper">
        {SHARE_BUTTONS.map((type) => (
          <ShareButton key={type} title={title} type={type} url={url} />
        ))}
      </div>
      <div className="popup__buttons">
        <Button
          className="popup__button"
          label={getLocalization('Close')}
          onClick={handleCloseButtonClick}
          size="oversize"
          tag="button"
          theme="outlined"
        />
      </div>
    </>
  );
};

export default PopupShare;
