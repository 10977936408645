import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import DetailsSection from './components/details-section';
import NotesSection from './components/notes-section';
import PersonForm from './components/person-form';
import ReservationButtons from './components/reservation-buttons';
import ReservationHeader from './components/reservation-header';

import reservationStore from 'src/stores/reservation-store';

import { useLocalization } from 'src/contexts/localization-context';
import useToggle from 'src/hooks/use-toggle';

import './reservation-page.scss';

const ReservationPage = observer(() => {
  const { id } = useParams();
  const [isConfirmationStage, toggleConfirmationStage] = useToggle();
  const ref = useRef<HTMLDivElement | null>(null);
  const { getPurchaserInfo, isLoading, isSameAsPurchaser, lotDetails } = reservationStore;
  const { getLocalization } = useLocalization();

  useEffect(() => {
    if (id) {
      reservationStore.init(id);
    }
  }, [id]);

  useEffect(() => {
    ref.current?.scrollIntoView();
  }, [isConfirmationStage]);

  return (
    <main className="reservation-page" ref={ref} id="main">
      <ReservationHeader isConfirmationStage={isConfirmationStage} />

      <DetailsSection data={lotDetails} title={getLocalization('Lot Details')} />

      {isConfirmationStage ? (
        isSameAsPurchaser ? (
          <DetailsSection
            data={getPurchaserInfo()}
            title={getLocalization('Rights Holder & Purchaser')}
          />
        ) : (
          <>
            <DetailsSection data={getPurchaserInfo()} title="Purchaser" />

            <DetailsSection data={getPurchaserInfo(true)} title="Rights Holder" />
          </>
        )
      ) : (
        <>
          <PersonForm />

          <PersonForm isDeedOwner />
        </>
      )}

      <NotesSection
        className={classNames(
          'reservation-page__notes',
          !isConfirmationStage && 'reservation-page__notes_editable'
        )}
        isConfirmationStage={isConfirmationStage}
      />

      <ReservationButtons
        isConfirmationStage={isConfirmationStage}
        toggleConfirmationStage={toggleConfirmationStage}
      />

      {isLoading ? <Loader className="loader_position_fixed" isDebounce={false} /> : null}
    </main>
  );
});

export default ReservationPage;
