import { useMemo } from 'react';

import useWindowSize from './use-window-size';

import { WindowWidth } from 'src/constants';

export default function useIsDesktop() {
  const windowSize = useWindowSize();

  return useMemo(() => windowSize.width >= WindowWidth.DESKTOP, [windowSize.width]);
}
