const DESKTOP_INNER_PAGE_FOOTER_LINKS_MAX_LENGTH = 65;
const DESKTOP_INNER_PAGE_FOOTER_LINKS_GAP_SYMBOLS_COUNT = 5;
export const BASE_ROUTE = process.env.PUBLIC_URL || '/';
export const PUBLIC_URL = process.env.PUBLIC_URL ?? '';
export const CEMETERY_LINK = window.CEMETERY_LINK ?? '';
export const CEMETERY_NAME = window.CEMETERY_NAME ?? '';
export const FOOTER_LINKS = window.FOOTER_LINKS || [];
export const GTM_ID = window.GOOGLE_TAG_MANAGER_ID;
export const THEME_REQUIRED_PROPERTY = '--color-theme-accent';
export const DEFAULT_CHECKBOX_TERMS_URL = 'https://allfuneral.com/terms.html';
export const DEFAULT_CHECKBOX_PRIVACY_URL = 'https://allfuneral.com/policy.html';
export const CHECKBOX_TERMS_URL = window.TERMS_OF_USE_URL || DEFAULT_CHECKBOX_TERMS_URL;
export const CHECKBOX_PRIVACY_URL = window.PRIVACY_POLICY_URL || DEFAULT_CHECKBOX_PRIVACY_URL;

export const CONFIG_REQUIRED_PARAMS: (keyof Window)[] = [
  'API_URL',
  'CEMETERY_ID',
  'CURRENCY',
  'GOOGLE_MAP_API_KEY',
  'MAIN_PAGE_DESCRIPTION',
  'MAIN_PAGE_TITLE',
  'TYPE_OF_LOTS',
];

export const CONFIG_REQUIRED_PARAMS_MAPPED = CONFIG_REQUIRED_PARAMS.map((item) => ({
  name: item,
  value: window[item],
}));

export const footerLinkslength = FOOTER_LINKS?.reduce(
  (sumLength, { title }) => sumLength + title?.length || 0,
  0
);
export const isDesktopInnerPageTwoLinesFooterLinks =
  footerLinkslength +
    (FOOTER_LINKS.length - 1) * DESKTOP_INNER_PAGE_FOOTER_LINKS_GAP_SYMBOLS_COUNT >
  DESKTOP_INNER_PAGE_FOOTER_LINKS_MAX_LENGTH;
