import React from 'react';
import classNames from 'classnames';

import Thumbs from 'src/components/common/image-viewer/components/thumbs';
import ImageWithFallback from 'src/components/common/image-with-fallback';
import ArrowButton from 'src/components/ui/arrow-button';
import noImage from 'src/assets/images/no-image.svg';

import useDraggableScroll from 'src/hooks/use-draggable-scroll';
import useImageViewer from 'src/hooks/use-image-viewer';
import useSwipe from 'src/hooks/use-swipe';
import { getImagesAlt } from 'src/utils';
import type { ImageViewerProps } from './image-viewer.props';

import './image-viewer.scss';

const ImageViewer = ({ images, initialIndex, imagesAlt }: ImageViewerProps) => {
  const {
    elementRef,
    handleImageLoad,
    handleNextBtnClick,
    handlePrevBtnClick,
    imageCurrentIndex,
    imageSrc,
    isControlsShouldBeShown,
    isDesktop,
    listRef,
    loadedImagesSizes,
    setImageCurrentIndex,
  } = useImageViewer(images, initialIndex);
  const { onMouseDown } = useDraggableScroll(listRef, { direction: 'horizontal' });
  const thumbsRef = React.useRef<HTMLDivElement>(null);

  const numberImages = images.length;

  useSwipe({
    left: handlePrevBtnClick,
    right: handleNextBtnClick,
    isListenerStopped: isDesktop,
    ignoredNode: thumbsRef.current,
  });

  return (
    <div className="image-viewer">
      <div className="image-viewer__images">
        {isControlsShouldBeShown && (
          <ArrowButton
            className="image-viewer__images-button"
            handleButtonClick={handlePrevBtnClick}
            modifier="previous"
          />
        )}
        <ul
          className={classNames(
            'image-viewer__images-list',
            isDesktop ? 'no-scrollbar' : 'image-viewer__images-list_mobile'
          )}
          ref={listRef}
          onMouseDown={isDesktop ? onMouseDown : undefined}
        >
          {images.map((image, idx) => (
            <li
              className={classNames(
                'image-viewer__images-item',
                (image.originImg !== imageSrc || imageCurrentIndex !== idx) &&
                  'image-viewer__images-item_hidden-desktop'
              )}
              key={`${idx}-${image.originImg}`}
              ref={idx === 0 ? elementRef : null}
            >
              <ImageWithFallback
                className="image-viewer__image"
                alt={getImagesAlt(imagesAlt, idx, numberImages)}
                fallbackSrc={noImage}
                objectFit="contain"
                onLoad={(e) => handleImageLoad(e.currentTarget)}
                src={image.originImg}
                {...(loadedImagesSizes[image.originImg]
                  ? {
                      style: {
                        maxHeight: loadedImagesSizes[image.originImg].height,
                        maxWidth: loadedImagesSizes[image.originImg].width,
                      },
                    }
                  : null)}
              />
            </li>
          ))}
        </ul>
        {isControlsShouldBeShown && (
          <ArrowButton
            className="image-viewer__images-button"
            handleButtonClick={handleNextBtnClick}
            modifier="next"
          />
        )}
      </div>
      {isControlsShouldBeShown && (
        <Thumbs
          handleImageClick={setImageCurrentIndex}
          images={images}
          imagesAlt={imagesAlt}
          imageCurrentIndex={imageCurrentIndex}
          ref={thumbsRef}
        />
      )}
    </div>
  );
};

export default ImageViewer;
