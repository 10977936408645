import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupWarn = observer(({ handleCloseButtonClick }: ICommonPopupProps) => {
  const { getLocalization } = useLocalization();
  const {
    title = getLocalization('Something went wrong'),
    text = getLocalization('Please try again later'),
  } = popupStore.getPopupState(PopupName.WARN);

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{title}</p>
        <p className="popup__text">{text}</p>
      </div>
      <Button
        className="popup__button"
        label={getLocalization('OK')}
        onClick={handleCloseButtonClick}
        size="oversize"
        theme="filled"
      />
    </>
  );
});

export default PopupWarn;
