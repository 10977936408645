import { mapLotToApi } from 'src/adapters';
import { doPost } from 'src/api/axios-config';
import { ApiRoute } from 'src/constants';
import type { IApiLotReservationPayload, IContactPersonFilled } from 'src/interfaces';

export async function fetchLotRequestCreate(
  data: {
    deedOwner: IContactPersonFilled;
    lotId: string;
    notes: string;
    purchaser: IContactPersonFilled;
  },
  onSuccess: () => void,
  onFail: () => void
) {
  try {
    await doPost<never, IApiLotReservationPayload>(ApiRoute.LOT_REQUEST, mapLotToApi(data));
    onSuccess();
  } catch (err) {
    console.log(err);
    onFail();
  }
}
