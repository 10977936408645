// google web mercator extent is a square and its bounds are -PI * 6378137, PI * 6378137 (EPSG:3857)
const EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];

/**
 * converting from x, y, and z to coordinates
 * https://justin.poehnelt.com/posts/google-maps-wms-layer/
 */
export function xyzToWMSBounds(x: number, y: number, z: number) {
  const tileSize = (EXTENT[1] * 2) / Math.pow(2, z);
  const minx = EXTENT[0] + x * tileSize;
  const maxx = EXTENT[0] + (x + 1) * tileSize;
  const miny = EXTENT[1] - (y + 1) * tileSize;
  const maxy = EXTENT[1] - y * tileSize;
  return [minx, miny, maxx, maxy];
}
