import type { FilterStore } from 'src/stores/filter-store';

import {
  AppRoute,
  FilterName,
  LotType,
  LotTypeShown,
  Rights,
  RightsType,
  SortDirection,
} from 'src/constants';
import { getOnlyNumbers } from 'src/utils/get-only-numbers';

type TFiltersFields = Pick<FilterStore, 'location' | 'priceRange' | 'rights' | 'sort' | 'type'>;

export const getFiltersSearchParamsObject = ({
  location,
  priceRange,
  rights,
  sort,
  type,
}: TFiltersFields) => ({
  sort: sort || SortDirection.ASC,
  ...(location && { [FilterName.LOCATION]: location }),
  ...(type && { [FilterName.TYPE]: type }),
  ...(rights && { [FilterName.RIGHTS]: rights }),
  ...(priceRange && { [FilterName.PRICE_RANGE]: `${priceRange.from}-${priceRange.to}` }),
});

export const getInitialFilters = (searchStr: string = window.location.search) => {
  try {
    const searchParams = new URLSearchParams(searchStr);
    const location = searchParams.get(FilterName.LOCATION);
    const type = searchParams.get(FilterName.TYPE) as LotType;
    const rights = searchParams.get(FilterName.RIGHTS) as RightsType;
    const sort = (searchParams.get(FilterName.SORT) as SortDirection) || SortDirection.ASC;
    const [priceFrom, priceTo] = (searchParams.get(FilterName.PRICE_RANGE) ?? '').split('-');
    const from = Number(getOnlyNumbers(priceFrom)) || 0;
    const to = Number(getOnlyNumbers(priceTo)) || 0;
    return {
      location,
      type: !!LotTypeShown[type] ? type : null,
      rights: !!Rights[rights] ? rights : null,
      priceRange: (from || to) && to >= from ? { from, to } : null,
      sort,
    };
  } catch {
    return {
      location: null,
      type: null,
      rights: null,
      priceRange: null,
      sort: null,
    };
  }
};

export const getLotTypeFilterUrl = (type: LotType) =>
  `${AppRoute.MAP_VIEW_PAGE}?${FilterName.SORT}=${SortDirection.ASC}&${FilterName.TYPE}=${type}`;

export const getFiltersSearchParamsString = (filtersFields: TFiltersFields) =>
  Object.entries(getFiltersSearchParamsObject(filtersFields))
    .map(([filterName, filterValue]) => `${filterName}=${filterValue}`)
    .join('&');
