import React from 'react';
import classNames from 'classnames';

import type { LabeledValueProps } from './labeled-value.props';

import './labeled-value.scss';

function LabeledValue({ className, label, value }: LabeledValueProps) {
  if (!value) {
    return null;
  }

  return (
    <span className={classNames('labeled-value', className)}>
      <span className="labeled-value__label">{label}:</span>

      <span className="labeled-value__value">{value}</span>
    </span>
  );
}

export default LabeledValue;
