import React from 'react';
import { scrollIntoView } from 'scroll-into-view-rtl-support';

import { WindowWidth } from 'src/constants';
import useWindowSize from 'src/hooks/use-window-size';
import { getNumericStyleValue } from 'src/utils';
import type { ILotImage } from 'src/interfaces';

export default function useImagesListScroll(images: ILotImage[], imageCurrentIndex: number) {
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = React.useState(width >= WindowWidth.LAPTOP);

  const elementRef = React.useRef<HTMLLIElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  const [isElementsFitContainerWidth, setIsElementsFitContainerWidth] = React.useState(false);
  const [isElementsFitContainerHeight, setIsElementsFitContainerHeight] = React.useState(false);
  const [listWidth, setListWidth] = React.useState(0);

  const updateStyleCalculation = React.useCallback(() => {
    if (listRef.current) {
      const elementWidth = elementRef.current ? elementRef.current.offsetWidth : 0;
      const elementHeight = elementRef.current ? elementRef.current.offsetHeight : 0;
      const listRowGap = getNumericStyleValue(listRef.current, 'row-gap');
      const listColumnGap = getNumericStyleValue(listRef.current, 'column-gap');
      const listHorizontalPaddings =
        getNumericStyleValue(listRef.current, 'padding-left') +
        getNumericStyleValue(listRef.current, 'padding-right') +
        getNumericStyleValue(listRef.current, 'width', 'after');
      const listVerticalPaddings =
        getNumericStyleValue(listRef.current, 'padding-bottom') +
        getNumericStyleValue(listRef.current, 'padding-top');

      setIsElementsFitContainerWidth(
        images.length * elementWidth +
          listColumnGap * (images.length - 1) +
          listHorizontalPaddings <
          listRef.current.offsetWidth
      );
      setIsElementsFitContainerHeight(
        images.length * elementHeight + listRowGap * (images.length - 1) + listVerticalPaddings <
          listRef.current.offsetHeight
      );
      setListWidth(listRef.current.offsetWidth);
    }
  }, [listRef, images]);

  React.useEffect(() => {
    setIsDesktop(width >= WindowWidth.LAPTOP);
    updateStyleCalculation();
  }, [updateStyleCalculation, width]);

  const scrollList = React.useCallback(
    (index: number) => {
      if (listRef.current) {
        const destinationNode = listRef.current.children[index];
        const baseScrollOptions: Omit<ScrollIntoViewOptions, 'behavior'> = {
          inline: 'center',
          block: 'start',
        };

        if (isDesktop) {
          if (destinationNode.className === 'thumb-item') {
            void scrollIntoView(destinationNode, {
              ...baseScrollOptions,
              behavior: 'smooth',
            });
          }
        } else {
          void scrollIntoView(destinationNode, {
            ...baseScrollOptions,
            duration: 500,
            scrollMode: 'if-needed',
          });
        }
      }
    },
    [isDesktop]
  );

  React.useEffect(() => {
    scrollList(imageCurrentIndex);
  }, [
    imageCurrentIndex,
    listWidth,
    isElementsFitContainerWidth,
    isElementsFitContainerHeight,
    scrollList,
  ]); // isElementsFitContainerWidth and isElementsFitContainerHeight useState changes have to recheck scroll position

  return {
    elementRef,
    isDesktop,
    isElementsFitContainerWidth,
    isElementsFitContainerHeight,
    listRef,
    updateStyleCalculation,
  };
}
