import React, { useCallback, useState } from 'react';
import { PolygonF } from '@react-google-maps/api';

import SectionMarker from '../section-marker';

import { ZoomLevel } from 'src/constants';
import type { ILatLngCoords } from 'src/interfaces';
import type { SectionPolygonProps } from './section-polygon.props';

const getPolygonMarkerCenter = (polygon: google.maps.Polygon | null, coords: ILatLngCoords[]) => {
  if (!polygon) {
    return null;
  }
  const bounds = new window.google.maps.LatLngBounds();
  let centerPoint;
  let testPos;
  const maxSearchSteps = 10;

  coords.map((element) => {
    bounds.extend(element);
  });
  centerPoint = bounds.getCenter();

  // Check if the point is inside polygon
  if (window.google.maps.geometry.poly.containsLocation(bounds.getCenter(), polygon)) {
    return { lat: centerPoint.lat(), lng: centerPoint.lng() };
  }
  const northWest = new window.google.maps.LatLng(
    bounds.getNorthEast().lat(),
    bounds.getSouthWest().lng()
  );
  // Work out how tall and wide the bounds are and what our search increment will be
  const boundsHeight = window.google.maps.geometry.spherical.computeDistanceBetween(
    northWest,
    bounds.getSouthWest()
  );
  const heightIncr = boundsHeight / maxSearchSteps;
  const boundsWidth = window.google.maps.geometry.spherical.computeDistanceBetween(
    northWest,
    bounds.getNorthEast()
  );
  const widthIncr = boundsWidth / maxSearchSteps;
  // Expand out from Centroid and find a point within polygon at 0, 90, 180, 270 degrees
  for (let n = 1; n <= maxSearchSteps; n++) {
    // Test point North of Centroid
    testPos = window.google.maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 0);
    if (window.google.maps.geometry.poly.containsLocation(testPos, polygon)) {
      break;
    }
    // Test point East of Centroid
    testPos = window.google.maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 90);
    if (window.google.maps.geometry.poly.containsLocation(testPos, polygon)) {
      break;
    }
    // Test point South of Centroid
    testPos = window.google.maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 180);
    if (window.google.maps.geometry.poly.containsLocation(testPos, polygon)) {
      break;
    }
    // Test point West of Centroid
    testPos = window.google.maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 270);
    if (window.google.maps.geometry.poly.containsLocation(testPos, polygon)) {
      break;
    }
  }

  if (testPos) {
    centerPoint = { lat: testPos.lat(), lng: testPos.lng() };
  }

  return centerPoint;
};

const SectionPolygon = ({
  onPolygonClick,
  section,
  showNumber,
  zoomLevel,
}: SectionPolygonProps) => {
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [isHover, setIsHover] = useState<boolean>(false);
  const { coords } = section;
  const polygonMarkerCenter = getPolygonMarkerCenter(polygon, coords);
  const isClickable = zoomLevel !== ZoomLevel.CLUSTERS;
  const polygonOptions = {
    fillOpacity: 0,
    strokeColor: isHover && isClickable ? '#14FF00' : 'white',
    strokeOpacity: 0.75,
    strokeWeight: 2,
    clickable: isClickable,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  const handleMouseOver = useCallback(() => {
    if (!isHover) {
      setIsHover(true);
    }
  }, [isHover]);

  const handleMouseOut = useCallback(() => {
    if (isHover) {
      setIsHover(false);
    }
  }, [isHover]);

  return (
    <>
      <PolygonF
        onClick={() => onPolygonClick(coords)}
        onLoad={(loadedPolygon) => setPolygon(loadedPolygon)}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        options={polygonOptions}
        paths={coords}
      />
      {showNumber && polygonMarkerCenter && (
        <SectionMarker sectionNumber={section.number} position={polygonMarkerCenter} />
      )}
    </>
  );
};

export default SectionPolygon;
