import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Portal from 'src/components/common/portal';
import PopupCancelRequest from '../popup-cancel-request';
import PopupGallery from '../popup-gallery';
import PopupShare from '../popup-share';
import PopupSuccessRequest from '../popup-success-request';
import PopupWarn from '../popup-warn';

import popupStore from 'src/stores/popup-store';

import { Key, PopupName, PopupReadableName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import useKeyUp from 'src/hooks/use-key-up';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import usePortalFocus from 'src/hooks/use-portal-focus';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import useWindowScrollBlock from 'src/hooks/use-window-scroll-block';
import type { ICommonPopupProps } from 'src/interfaces';
import type { PopupProps } from './popup.props';

import './popup.scss';

const POPUP_COMPONENTS: Record<PopupName, React.FC<ICommonPopupProps>> = {
  [PopupName.CANCEL_REQUEST]: PopupCancelRequest,
  [PopupName.GALLERY]: PopupGallery,
  [PopupName.SUCCESS_REQUEST]: PopupSuccessRequest,
  [PopupName.WARN]: PopupWarn,
  [PopupName.SHARE]: PopupShare,
};

const Popup = observer(({ popupName }: PopupProps) => {
  const popupRef = React.useRef<HTMLDivElement | null>(null);
  const { className, isVisible, onClose } = popupStore.getPopupState(popupName);
  const PopupComponent = POPUP_COMPONENTS[popupName] || null;
  const { getLocalization } = useLocalization();

  const closePopup = () => {
    popupStore.hidePopup(popupName);
    onClose?.();
  };

  const { unmountComponent, handleAnimationEnd, isUnmounting } = useUnmountAnimation(closePopup);

  useOnClickOutside(popupRef, unmountComponent);
  useKeyUp(Key.ESCAPE, unmountComponent);
  useWindowScrollBlock(true);
  usePortalFocus(popupRef);

  return (
    <Portal>
      <div
        className={classNames(
          'popup',
          className,
          isUnmounting && 'popup_hiding',
          `popup_${popupName}`,
          'scrollbar'
        )}
      >
        <div
          className="popup__wrapper scrollbar"
          aria-label={getLocalization(PopupReadableName[popupName])}
          aria-modal
          role="dialog"
          ref={popupRef}
          tabIndex={-1}
          onAnimationEnd={handleAnimationEnd}
        >
          {isVisible && <PopupComponent handleCloseButtonClick={unmountComponent} />}
        </div>
      </div>
    </Portal>
  );
});

export default Popup;
