import React from 'react';
import ReactDOM from 'react-dom';

import type { PortalProps } from './portal.props';

const Portal = ({ children }: PortalProps) => {
  const el = React.useMemo(() => document.createElement('div'), []);

  React.useEffect(() => {
    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};
export default Portal;
