import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import type { ImageWithFallbackProps } from './image-with-fallback.props';

import './image-with-fallback.scss';

const ImageWithFallback = ({
  className,
  alt,
  fallbackSrc,
  objectFit = 'cover',
  setErrorLoadingImage,
  src,
  ...props
}: ImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState<string>(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleImageError = useCallback(() => {
    setImgSrc(fallbackSrc);
    setErrorLoadingImage && setErrorLoadingImage(true);
  }, [fallbackSrc, setErrorLoadingImage]);

  useEffect(() => {
    if (!imgSrc) {
      handleImageError();
    }
  }, [imgSrc, handleImageError]);

  const imageClasses = classNames(
    'image-with-fallback',
    `image-with-fallback_${objectFit}`,
    className
  );

  return (
    <img className={imageClasses} src={imgSrc} alt={alt} onError={handleImageError} {...props} />
  );
};

export default React.memo(ImageWithFallback);
