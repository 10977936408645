import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import Chip from 'src/components/ui/chip';

import filterStore from 'src/stores/filter-store';

import { LotTypeShown, Rights } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { formatPriceRange, getSectionName } from 'src/utils';

import './filters-view.scss';

const FiltersView = observer(() => {
  const { getLocalization } = useLocalization();
  const {
    location,
    mausoleumsOptions,
    priceRange,
    rights,
    sectionsOptions,
    type,
    handleChangeLocation,
    handleChangePriceRange,
    handleChangeRights,
    handleChangeType,
  } = filterStore;

  const locationName = useMemo(() => {
    return (
      (location &&
        (mausoleumsOptions.find(({ id }) => location === id)?.value ||
          getSectionName(
            sectionsOptions.find(({ id }) => location === id)?.value ?? '',
            getLocalization
          ))) ||
      ''
    );
  }, [getLocalization, location, mausoleumsOptions, sectionsOptions]);

  return (
    <div className="filters-view scrollbar scrollbar_horizontal">
      {location && (
        <Chip isWithClose text={locationName} onClick={() => handleChangeLocation('')} />
      )}
      {priceRange && (
        <Chip
          isWithClose
          text={formatPriceRange(priceRange)}
          onClick={() => handleChangePriceRange(0, 0)}
        />
      )}
      {type && (
        <Chip
          isWithClose
          text={getLocalization(LotTypeShown[type])}
          onClick={() => handleChangeType('')}
        />
      )}
      {rights && (
        <Chip
          isWithClose
          text={getLocalization(Rights[rights])}
          onClick={() => handleChangeRights('')}
        />
      )}
    </div>
  );
});

export default FiltersView;
