export const CURRENCY_CENTS_COUNT = 100;

export const CurrencyCode = {
  USD: 'USD',
  ILS: 'ILS',
};

export const APP_CURRENCY =
  CurrencyCode[window.CURRENCY as keyof typeof CurrencyCode] || CurrencyCode.USD;

export const LOCALE_STRING = {
  [CurrencyCode.USD]: 'en-US',
  [CurrencyCode.ILS]: 'he-IL',
}[APP_CURRENCY];

export const CURRENCY_SIGN = {
  [CurrencyCode.USD]: '$',
  [CurrencyCode.ILS]: '₪',
}[APP_CURRENCY];
