import React from 'react';

import type { LogoTextProps } from './logo-text.props';

import './logo-text.scss';

const LogoText = ({ location, title, subtitle }: LogoTextProps) => {
  return (
    <p className={`logo-text logo-text_${location}`}>
      {title && <span className="logo-text__title">{title}</span>}
      {subtitle && <span className="logo-text__subtitle">{subtitle}</span>}
    </p>
  );
};

export default LogoText;
