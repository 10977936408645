import { MutableRefObject, useEffect } from 'react';

export default function usePortalFocus(ref: MutableRefObject<HTMLElement | null>) {
  useEffect(() => {
    const lastFocusedElement = document.activeElement;
    const appElement = document.querySelector('.app');

    ref.current?.focus();
    if (appElement instanceof HTMLElement) {
      appElement.inert = true;
    }
    return () => {
      if (appElement instanceof HTMLElement) {
        appElement.inert = false;
      }
      if (lastFocusedElement instanceof HTMLElement) {
        lastFocusedElement.focus();
      }
    };
  }, [ref]);
}
