export const MAP_CEMETERY_ZOOM = window.MAP_CEMETERY_ZOOM || 15;
export const MAP_DEFAULT_ZOOM = window.MAP_DEFAULT_ZOOM || 15;
export const MAP_CLUSTER_ZOOM = window.MAP_CLUSTER_ZOOM || 20;
export const MAP_LOT_DEFAULT_ZOOM = window.MAP_LOT_DEFAULT_ZOOM || 21;
export const MAP_MAX_ZOOM_OUT = window.MAP_MAX_ZOOM_OUT || 0;
export const MAP_OVERLAY_WIDTH = 426;
export const MAP_TILE_DEFAULT_SIZE = 256;

export const ZoomLevel = {
  CLUSTERS: 'clusters',
  SECTIONS: 'sections',
  CEMETERY: 'cemetery',
};
