import { LotType, Regex } from 'src/constants';
import { getCoords } from 'src/utils';
import type { IApiLot, ICoords, ILot, ILotImage, ILotMedia, IPhoto } from 'src/interfaces';

const getVideoCode = (url: string) => {
  const videoId = url.match(Regex.YOUTUBE);
  return videoId && videoId.length && typeof videoId[1] === 'string' ? videoId[1] : '';
};

const getPreviewImage = (photos: IPhoto[] = []) => {
  const image = photos[0];

  if (image) {
    return `${image.cdnUrl}${image.medium || image.origin}`;
  }

  return '';
};

const getPhotos = (photos: IPhoto[] = []): ILotImage[] =>
  photos.map((photo, idx) => ({
    id: idx,
    type: 'image',
    img: `${photo.cdnUrl}${photo.medium || photo.origin}`,
    originImg: `${photo.cdnUrl}${photo.origin}`,
  }));

const getMedia = (photos: IPhoto[] = [], video?: string): ILotMedia[] => {
  const media: ILotMedia[] = getPhotos(photos);
  const videoId = video ? getVideoCode(video) : '';

  if (videoId) {
    media.push({
      id: photos.length,
      type: 'video',
      videoId,
    });
  }

  return media;
};

const getPlotType = (type: TLotType) => LotType[type] || '';

export const mapApiToLot = (lot: IApiLot): ILot => ({
  bank: lot.bank?.name || '',
  block: lot.block || '',
  coords: lot.location?.coordinates
    ? getCoords(lot.location.coordinates)
    : getCoords(lot.mausoleum?.location?.coordinates as ICoords),
  description: lot.description || '',
  dimensions: `${lot.dimensions?.width || ''} x ${lot.dimensions?.length || ''}`,
  id: lot.id,
  number: lot.no || '',
  rights: lot.right,
  row: lot?.row !== undefined ? lot.row.toString() : '',
  media: getMedia(lot?.photos, lot.video_url),
  photos: getPhotos(lot?.photos),
  previewImage: getPreviewImage(lot?.photos),
  mausoleum: lot?.mausoleum ? lot.mausoleum : null,
  tier: lot.tier !== undefined ? lot.tier.toString() : '',
  price: lot.price,
  section: lot.section?.no || '',
  type: getPlotType(lot.type),
});
