import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MarkerClustererF, OverlayView, OverlayViewF, PolygonF } from '@react-google-maps/api';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Map from 'src/components/common/map';
import MapMarker from 'src/components/common/map-marker';
import MapModal from 'src/components/common/map-modal';
import Sections from './components/sections';
import ClusterImage from 'src/assets/icons/cluster.svg';

import {
  MAP_CLUSTER_ZOOM,
  MAP_DEFAULT_ZOOM,
  MAP_OVERLAY_WIDTH,
  MarkerType,
  ZoomLevel,
} from 'src/constants';
import useIntersect from 'src/hooks/use-intersect';
import { getPixelPositionOffset } from 'src/utils';
import type { ILatLngCoords, ILot } from 'src/interfaces';
import type { CemeteryMapProps } from './cemetery-map.props';

const clustererOptions = {
  averageCenter: true,
  maxZoom: MAP_CLUSTER_ZOOM,
  styles: [
    {
      textColor: 'white',
      url: ClusterImage,
      height: 32,
      width: 32,
    },
  ],
};

const cemeteryPathsOptions = {
  fillOpacity: 0,
  strokeColor: 'white',
  strokeOpacity: 0.75,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const CemeteryMap = observer(
  ({
    className,
    center,
    cemeteryPaths,
    height,
    width,
    lots,
    mapStore,
    mausoleumsCoords,
    selectedLot,
    handleLotMarkerClick,
    resetSelectedLot,
  }: CemeteryMapProps) => {
    const {
      isZoomEnablesPopupShow,
      map,
      mapZoom,
      zoomLevel,
      handleHomeButtonClick,
      moveCenter,
      onLoad,
      onZoom,
      setSelectedMarker,
    } = mapStore;
    const shouldObserveModal = useRef(true);
    const [modal, setModal] = useState<google.maps.OverlayView | null>(null);
    const [modalRef, entry] = useIntersect(!modal);

    const setShouldObserveModal = (bool: boolean) => {
      shouldObserveModal.current = bool;
    };

    useEffect(() => {
      setSelectedMarker(selectedLot);

      if (selectedLot && !shouldObserveModal?.current) {
        setShouldObserveModal(true);
      }
    }, [selectedLot, setSelectedMarker]);

    useEffect(() => {
      if (modal && !shouldObserveModal?.current) {
        setShouldObserveModal(true);
      }
    }, [modal]);

    useEffect(() => {
      if (selectedLot && 'isIntersecting' in entry) {
        if (!entry.isIntersecting && shouldObserveModal.current) {
          moveCenter(selectedLot.coords, MAP_OVERLAY_WIDTH / 2);
          setShouldObserveModal(false);
        } else if (entry.isIntersecting && shouldObserveModal.current) {
          setShouldObserveModal(false);
        }
      }
    }, [entry, moveCenter, selectedLot]);

    const handleMapClick = useCallback(() => {
      if (selectedLot) {
        resetSelectedLot();
      }
    }, [selectedLot, resetSelectedLot]);

    const handlePolygonClick = (polygonCoords: ILatLngCoords[]) => {
      const bounds = new window.google.maps.LatLngBounds();
      polygonCoords.map((element) => {
        bounds.extend(element);
      });
      if (zoomLevel !== ZoomLevel.CLUSTERS) {
        map?.fitBounds(bounds);
      }
      handleMapClick();
    };

    return (
      <Map
        className={classNames('cemetery-map', className)}
        center={center}
        height={height}
        onClick={handleMapClick}
        onLoad={onLoad}
        onZoomChanged={onZoom}
        zoom={mapZoom}
        width={width}
        handleHomeButtonClick={() => handleHomeButtonClick(MAP_DEFAULT_ZOOM)}
      >
        <PolygonF paths={cemeteryPaths} options={cemeteryPathsOptions} />
        {(zoomLevel === ZoomLevel.SECTIONS || zoomLevel === ZoomLevel.CLUSTERS) && (
          <Sections
            onPolygonClick={handlePolygonClick}
            showNumber={zoomLevel === ZoomLevel.SECTIONS}
            zoomLevel={zoomLevel}
          />
        )}
        {zoomLevel === ZoomLevel.CLUSTERS && (
          <>
            <MarkerClustererF options={clustererOptions}>
              {(clusterer) => (
                <>
                  {lots.map((lot: ILot) => (
                    <MapMarker
                      clusterer={clusterer}
                      key={lot.id}
                      type={selectedLot?.id === lot.id ? MarkerType.BIG_DOT : MarkerType.DOT}
                      onClick={() => handleLotMarkerClick(lot)}
                      position={lot.coords}
                    />
                  ))}
                </>
              )}
            </MarkerClustererF>
            {mausoleumsCoords.map((coords: ILatLngCoords, idx) => (
              <MapMarker key={`marker-${idx}`} type={MarkerType.MAUSOLEUM} position={coords} />
            ))}
          </>
        )}
        {selectedLot && isZoomEnablesPopupShow && (
          <OverlayViewF
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={selectedLot.coords}
            onLoad={(loadedModal) => setModal(loadedModal)}
            onUnmount={() => setModal(null)}
          >
            <MapModal lot={selectedLot} ref={modalRef} />
          </OverlayViewF>
        )}
      </Map>
    );
  }
);

export default CemeteryMap;
