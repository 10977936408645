import React from 'react';
import { Helmet, HelmetData } from 'react-helmet-async';

import { getDataStructure } from 'src/utils';
import type { SchemaHelmetProps } from './schema-helmet.props';

const SchemaHelmet = ({ lot, cemetery }: SchemaHelmetProps) => {
  const helmetData = new HelmetData({});

  return (
    <Helmet helmetData={helmetData}>
      <script type="application/ld+json">{getDataStructure(lot, cemetery)}</script>
    </Helmet>
  );
};

export default SchemaHelmet;
