import React, { createContext, useContext } from 'react';
import { LoadScriptProps, useJsApiLoader } from '@react-google-maps/api';

import type { GoogleMapsContextProps } from './google-maps-context.props';

const GoogleMapsContext = createContext({ isLoaded: false });

const libraries: LoadScriptProps['libraries'] = ['places', 'geometry'];

const GoogleMapsProvider = ({ children }: GoogleMapsContextProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.GOOGLE_MAP_API_KEY,
    language: 'en',
    libraries,
  });

  return <GoogleMapsContext.Provider value={{ isLoaded }}>{children}</GoogleMapsContext.Provider>;
};

const useGoogleMaps = () => useContext(GoogleMapsContext);

export { GoogleMapsProvider, useGoogleMaps };
