import { LotType } from './lot';
import cremationPlot from 'src/assets/images/cremation-plot.svg';
import crypt from 'src/assets/images/crypt.svg';
import niche from 'src/assets/images/niche.svg';
import plot from 'src/assets/images/plot.svg';

export const NoImageLot = {
  [LotType.plot]: plot,
  [LotType.cremation_plot]: cremationPlot,
  [LotType.niche]: niche,
  [LotType.crypt]: crypt,
};
