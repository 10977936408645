import MausoleumMarkerImage from 'src/assets/icons/mausoleum-marker.svg';
import PinMarkerImage from 'src/assets/icons/pin-marker.svg';
import SectionMarkerImage from 'src/assets/icons/section.svg';

import type { MarkerType } from 'src/constants';

const MAUSOLEUM_MARKER_HEIGHT = 20;
const MAUSOLEUM_MARKER_WIDTH = 20;
const PIN_MARKER_HEIGHT = 32;
const PIN_MARKER_WIDTH = 32;

const dotIcon: google.maps.Symbol = {
  path: 'M0,6a6,6 0 1,0 12,0a6,6 0 1,0 -12,0',
  fillColor: '#0087CF',
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: '#F7F7F7',
  rotation: 0,
  scale: 1,
  anchor: { x: 6, y: 6, equals: () => true },
};

const bigDotIcon: google.maps.Symbol = {
  ...dotIcon,
  scale: 1.6,
};

const mausoleumIcon: google.maps.Icon = {
  url: MausoleumMarkerImage,
  anchor: { x: MAUSOLEUM_MARKER_WIDTH / 2, y: MAUSOLEUM_MARKER_HEIGHT / 2, equals: () => true },
};

const pinIcon: google.maps.Icon = {
  url: PinMarkerImage,
  anchor: { x: PIN_MARKER_WIDTH / 2, y: PIN_MARKER_HEIGHT, equals: () => true },
};

const sectionIcon: google.maps.Icon = {
  url: SectionMarkerImage,
  labelOrigin: { x: 17, y: 9, equals: () => true },
};

export function getMarkerIcon(type: MarkerType) {
  return (
    {
      dot: dotIcon,
      bigDot: bigDotIcon,
      pin: pinIcon,
      mausoleum: mausoleumIcon,
      section: sectionIcon,
    }[type] || ''
  );
}

export function getMarkerLabel(text: string) {
  return {
    text,
    color: '#f7f7f7',
    fontSize: '12px',
    fontWeight: '700',
  };
}
