import { ReactComponent as GlobeIcon } from 'src/assets/icons/globe-icon.svg';
import { ReactComponent as MailIcon } from 'src/assets/icons/mail-icon.svg';
import { ReactComponent as PhoneIcon } from 'src/assets/icons/phone-icon.svg';

import { CEMETERY_LINK } from 'src/constants';
import { formatApiToPhone, getDomain } from 'src/utils';

export const getContactLinks = ({ email, phone }: { email: string; phone: string }) => {
  return [
    {
      link: `tel:+${phone}`,
      label: formatApiToPhone(phone || ''),
      icon: PhoneIcon,
      target: '_self',
    },
    {
      link: `mailto:${email}`,
      label: email,
      icon: MailIcon,
      target: '_self',
    },
    {
      link: CEMETERY_LINK,
      label: getDomain(CEMETERY_LINK),
      icon: GlobeIcon,
      target: '_blank',
    },
  ].filter((item) => item.label);
};
