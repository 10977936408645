import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Input from 'src/components/ui/input';

import reservationStore from 'src/stores/reservation-store';

import { InputMaxLength } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { NoteSectionProps } from './notes-section.props';

import './notes-section.scss';

const NotesSection = observer(({ className, isConfirmationStage }: NoteSectionProps) => {
  const { notes, setNotes } = reservationStore;
  const { getLocalization } = useLocalization();
  const title = getLocalization('Notes');

  return (
    <section className={classNames('notes-section', className)} aria-label={title}>
      <h2 className="notes-section__title">{title}</h2>
      {isConfirmationStage ? (
        <p className="notes-section__text">{notes || '-'}</p>
      ) : (
        <Input
          hasCounter
          maxLength={InputMaxLength.NOTES}
          placeholder={getLocalization('Add your comment if necessary')}
          rows={3}
          value={notes}
          onChange={setNotes}
        />
      )}
    </section>
  );
});

export default NotesSection;
