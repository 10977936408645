import React from 'react';

export default function useResizeObserver(
  targetRef: React.RefObject<HTMLElement>,
  handler: () => void
) {
  const observer = React.useRef<ResizeObserver | null>(null);

  React.useEffect(() => {
    if (!('ResizeObserver' in window)) {
      return;
    }

    if (observer && observer.current) {
      observer.current.disconnect();
    }

    observer.current = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          handler();
        }
      }
    });
  }, [handler, observer]);

  React.useEffect(() => {
    if (!('ResizeObserver' in window)) {
      return;
    }

    if (!observer.current || !targetRef.current) {
      return;
    }

    observer.current.observe(targetRef.current);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [observer, targetRef]);
}
