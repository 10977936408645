import { AppRoute, LOT_DETAILS, Rights, ROUTE_ID } from 'src/constants';
import type { ILot } from 'src/interfaces';

export const getLotDetails = (lot: ILot) => {
  return LOT_DETAILS.filter(({ field }) => !!lot[field]).map(({ field: lotField, label }) => {
    const field = lotField === 'rights' ? Rights[lot.rights] : lot[lotField];
    return { label, field };
  });
};

export const getLotUrl = (lotId: string, isExternalLink?: boolean) => {
  const publicUrl = isExternalLink ? window.location.origin : '';
  return `${publicUrl}${AppRoute.LOT_PAGE.replace(ROUTE_ID, lotId)}`;
};
