export const getNumericStyleValue = (
  element: Element,
  styleProp: string,
  pseudo?: 'before' | 'after'
) =>
  window && element
    ? Number(
        window
          .getComputedStyle(element, pseudo ? `:${pseudo}` : null)
          .getPropertyValue(styleProp)
          .replace(/\D/g, '')
      ) || 0
    : 0;
