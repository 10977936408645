import React, { KeyboardEvent } from 'react';
import classNames from 'classnames';

import Input from 'src/components/ui/input';

import { Key } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { RangeProps } from './range.props';

import './range.scss';

const Range = ({
  className,
  endValue,
  maxValue,
  minValue,
  startValue,
  handleBlurMaxValue,
  handleBlurMinValue,
  setEndValue,
  setStartValue,
}: RangeProps) => {
  const { getLocalization } = useLocalization();
  const handleInputEnterUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === Key.ENTER) {
      (evt.target as HTMLInputElement).blur();
    }
  };

  return (
    <div
      className={classNames('range', className)}
      role="menuitem"
      aria-label={getLocalization('Set range values')}
    >
      <Input
        className="range__input"
        inputMode="numeric"
        onBlur={handleBlurMinValue}
        onChange={setStartValue}
        placeholder={minValue}
        value={startValue}
        onKeyUp={handleInputEnterUp}
      />
      <span className="range__divider" role="presentation">
        -
      </span>
      <Input
        className="range__input"
        inputMode="numeric"
        onBlur={handleBlurMaxValue}
        onChange={setEndValue}
        placeholder={maxValue}
        value={endValue}
        onKeyUp={handleInputEnterUp}
      />
    </div>
  );
};

export default React.memo(Range);
