import React from 'react';
import classNames from 'classnames';

import ImageWithFallback from 'src/components/common/image-with-fallback';
import noImage from 'src/assets/images/no-image.svg';

import type { ThumbItemProps } from './thumb-item.props';

import './thumb-item.scss';

const ThumbItem = React.forwardRef<HTMLLIElement, ThumbItemProps>(
  ({ className, image, imagesAlt, isActive, handleImageClick }: ThumbItemProps, ref) => {
    return (
      <li className={classNames('thumb-item', className)} ref={ref}>
        <button
          className={classNames('thumb-item__button', isActive && 'thumb-item__button_active')}
          onClick={handleImageClick}
        >
          <ImageWithFallback
            className="thumb-item__image"
            alt={imagesAlt}
            fallbackSrc={noImage}
            src={image.img}
          />
        </button>
      </li>
    );
  }
);

export default ThumbItem;
