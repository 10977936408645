import { makeAutoObservable, reaction } from 'mobx';

import filterStore from './filter-store';

import { fetchLots } from 'src/api';
import { LotType } from 'src/constants';
import type { ILatLngCoords, ILot } from 'src/interfaces';

class LotsViewStore {
  isLoading = true;
  lots: ILot[] = [];
  selectedLot: ILot | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => [filterStore.apiParams, filterStore.isLoading],
      () => this.loadLots()
    );

    reaction(
      () => this.lots,
      () => this.checkSelectedLot()
    );
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setLots(lots: ILot[]) {
    this.lots = lots;
  }

  setSelectedLot(lot: ILot | null) {
    // set lot copy to enable lot back navigation if it's already selected
    this.selectedLot = lot ? { ...lot } : null;
  }

  resetSelectedLot() {
    this.setSelectedLot(null);
  }

  checkSelectedLot() {
    if (!this.selectedLot) {
      return;
    }

    if (!this.lots.length) {
      this.resetSelectedLot();
      return;
    }

    const lotInList = this.lots.find((item) => item.id === this.selectedLot!.id);

    if (!lotInList) {
      this.resetSelectedLot();
    }
  }

  get inGroundLots() {
    return this.lots.filter(
      (item) => item.type === LotType.cremation_plot || item.type === LotType.plot
    );
  }

  get mausoleumLots() {
    return this.lots.filter((item) => item.type === LotType.niche || item.type === LotType.crypt);
  }

  get mausoleumCoords() {
    const coords: any = {};

    this.mausoleumLots.forEach((item) => {
      if (!coords[item.mausoleum!.id]) {
        coords[item.mausoleum!.id] = {
          lat: item.mausoleum?.location.coordinates[1],
          lng: item.mausoleum?.location.coordinates[0],
        };
      }
    });

    return Object.values(coords) as ILatLngCoords[];
  }

  async loadLots() {
    this.setLoading(true);
    const lots = await fetchLots(filterStore.apiParams);
    this.setLots(lots || []);
    this.setLoading(false);
  }
}

export default LotsViewStore;
