import React, { forwardRef } from 'react';
import { observer } from 'mobx-react-lite';

import ImageWithFallback from 'src/components/common/image-with-fallback';
import Button from 'src/components/ui/button';

import popupStore from 'src/stores/popup-store';

import { AppRoute, LotTypeShown, NoImageLot, PopupName, Rights } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { formatCents, getLotUrl } from 'src/utils';
import type { MapModalProps } from './map-modal.props';

import './map-modal.scss';

const MapModal = observer(
  forwardRef<HTMLDivElement, MapModalProps>(
    (
      { lot: { id, mausoleum, number, photos, previewImage, price, section, rights, type } },
      ref
    ) => {
      const { getLocalization } = useLocalization();
      const getLocationParam = () => {
        return mausoleum?.name ? (
          <>
            <dt className="map-modal__parameter-key">{getLocalization('Mausoleum')}:</dt>
            <dd className="map-modal__parameter-value">{mausoleum.name}</dd>
          </>
        ) : (
          <>
            <dt className="map-modal__parameter-key">{getLocalization('Section')}:</dt>
            <dd className="map-modal__parameter-value">{section}</dd>
          </>
        );
      };

      const handleImageClick = () => {
        if (photos.length) {
          popupStore.showPopup(PopupName.GALLERY, {
            images: photos,
            imagesAlt: `${getLocalization(LotTypeShown[type])} ${number} ${getLocalization(
              'photo'
            )}`,
            initialIndex: 0,
          });
        }
      };

      return (
        <section
          className="map-modal"
          aria-label={getLocalization('Selected Lot Details')}
          ref={(sectionRef) =>
            sectionRef && window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(sectionRef)
          }
        >
          <div className="map-modal__wrapper" ref={ref}>
            <div className="map-modal__info">
              <p className="map-modal__title">
                {getLocalization(LotTypeShown[type])} {number}
              </p>
              <dl className="map-modal__parameters">
                {getLocationParam()}
                <dt className="map-modal__parameter-key">{getLocalization('Rights')}:</dt>
                <dd className="map-modal__parameter-value">{getLocalization(Rights[rights])}</dd>
                <dt className="map-modal__parameter-key">{getLocalization('Price')}:</dt>
                <dd className="map-modal__parameter-value  map-modal__parameter-value_bold">
                  {formatCents(price)}
                </dd>
              </dl>
              <div className="map-modal__links">
                <Button
                  className="map-modal__link"
                  tag="link"
                  theme="filled"
                  size="flattened"
                  href={`${AppRoute.RESERVATION_PAGE}/${id}`}
                  label={getLocalization('Request')}
                  target="_blank"
                />
                <Button
                  className="map-modal__link"
                  tag="link"
                  theme="outlined"
                  size="flattened"
                  href={getLotUrl(id)}
                  label={getLocalization('More Info')}
                  target="_blank"
                />
              </div>
            </div>
            <button
              className="map-modal__photo"
              disabled={!photos.length}
              onClick={handleImageClick}
            >
              <ImageWithFallback
                alt="Lot preview"
                className="map-modal__photo-image"
                fallbackSrc={NoImageLot[type]}
                src={previewImage || NoImageLot[type]}
              />
            </button>
          </div>
        </section>
      );
    }
  )
);

export default MapModal;
