import type { MutableRefObject } from 'react';
import { useLayoutEffect } from 'react';

import { WindowWidth } from 'src/constants';

export default function useLotsHeight(
  ref: MutableRefObject<HTMLElement | null>,
  headerHeight: number,
  windowWidth: number
) {
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const filterAndSortHeight = windowWidth >= WindowWidth.LAPTOP ? 122 : 116;
    const heightDiff = filterAndSortHeight + headerHeight;
    ref.current.style.height = `calc(var(--vh, 1vh) * 100 - ${heightDiff}px)`;
  }, [headerHeight, ref, windowWidth]);
}
