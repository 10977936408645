export enum LotType {
  cremation_plot = 'cremation_plot',
  crypt = 'crypt',
  niche = 'niche',
  plot = 'plot',
}

export const LotTypeShown = {
  [LotType.cremation_plot]: 'Cremation Plot',
  [LotType.crypt]: 'Crypt',
  [LotType.niche]: 'Niche',
  [LotType.plot]: 'Plot',
};

export const ORDERED_LOT_LIST = [
  LotType.plot,
  LotType.cremation_plot,
  LotType.niche,
  LotType.crypt,
];

export const TYPE_OF_LOTS = window.TYPE_OF_LOTS || [];

export const AVAILABLE_LOT_TYPES = ORDERED_LOT_LIST.filter((type) => TYPE_OF_LOTS.includes(type));

export const DEFAULT_LOT_TYPE = AVAILABLE_LOT_TYPES.length === 1 ? AVAILABLE_LOT_TYPES[0] : null;
