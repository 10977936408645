import React, { useCallback, useEffect, useState } from 'react';

import LotCard from 'src/components/common/lot-card';

import type { ILot } from 'src/interfaces';
import type { MapLotsListProps } from './map-lots-list.props';

import './map-lots-list.scss';

const MapLotsList = ({ isLaptop, lots, selectedLot, onLotClick }: MapLotsListProps) => {
  const [selectedLotId, setSelectedLotId] = useState('');

  useEffect(() => {
    setSelectedLotId(selectedLot?.id ?? '');
  }, [selectedLot]);

  const handleLotCardClick = useCallback(
    (item: ILot) => () => {
      if (isLaptop) {
        onLotClick(item);
      }
    },
    [onLotClick, isLaptop]
  );

  return (
    <ul className="map-lots-list">
      {lots.map((item) => (
        <li className="map-lots-list__item" key={item.id}>
          <LotCard lot={item} isSelected={item.id === selectedLotId} onClick={handleLotCardClick} />
        </li>
      ))}
    </ul>
  );
};

export default React.memo(MapLotsList);
