import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import LoaderWrapper from './components/loader-wrapper';

import type { LoaderProps } from './loader.props';

import './loader.scss';

const DEBOUNCE_INTERVAL = 300;

const Loader = ({
  className,
  debounceTime = DEBOUNCE_INTERVAL,
  isDebounce = true,
  isInline,
}: LoaderProps) => {
  const [showComponent, setShowComponent] = useState(!isDebounce);

  useEffect(() => {
    const timer = setTimeout(() => setShowComponent(true), debounceTime);
    return () => clearTimeout(timer);
  }, [debounceTime]);

  return showComponent ? (
    <LoaderWrapper className={classNames('loader', className)} isInline={isInline}>
      <svg className="loader__spinner" viewBox="0 0 50 50">
        <circle
          className="loader__spinner-path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    </LoaderWrapper>
  ) : null;
};

export default Loader;
