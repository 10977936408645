import { useRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import LotMap from 'src/components/common/lot-map';
import Portal from 'src/components/common/portal';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as IconCancel } from 'src/assets/icons/cross-icon.svg';

import globalAppStore from 'src/stores/global-app-store';
import locationScreenStore from 'src/stores/location-screen-store';

import { useLocalization } from 'src/contexts/localization-context';
import usePortalFocus from 'src/hooks/use-portal-focus';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import type { LocationScreenProps } from './location-screen.props';

import './location-screen.scss';

const LocationScreen = observer(({ lotName, coords }: LocationScreenProps) => {
  const { hide } = locationScreenStore;
  const { cemetery } = globalAppStore;
  const screenRef = useRef<HTMLDivElement | null>(null);
  const { unmountComponent, handleAnimationEnd, isUnmounting } = useUnmountAnimation(hide);
  const { getLocalization } = useLocalization();
  const title = getLocalization('Lot Location');

  usePortalFocus(screenRef);

  return (
    <Portal>
      <div
        className={classNames('location-screen', isUnmounting && 'location-screen_hide')}
        ref={screenRef}
        aria-label={title}
        aria-modal
        role="dialog"
        tabIndex={-1}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="location-screen__wrapper">
          <div className="location-screen__header">
            <h1 className="location-screen__title">
              {title} - <b>{lotName}</b>
            </h1>
            <IconButton
              className="location-screen__header-icon"
              ariaLabel="Hide location screen"
              icon={<IconCancel />}
              onClick={unmountComponent}
            />
          </div>
          <div className="location-screen__body">
            {cemetery ? (
              <LotMap
                className="lot-page__map"
                cemetery={cemetery}
                coords={coords}
                height="100%"
                width="100%"
                isFullScreen
                handleFullScreenButtonClick={hide}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default LocationScreen;
