import React, { useEffect, useId, useRef, useState } from 'react';
import classNames from 'classnames';

import IconButton from 'src/components/ui/icon-button';
import Input from 'src/components/ui/input';
import Label from 'src/components/ui/label';
import { ReactComponent as Arrow } from 'src/assets/icons/chevron-down.svg';
import { ReactComponent as Cross } from 'src/assets/icons/cross-icon.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';

import { Key } from 'src/constants';
import useKeyUp from 'src/hooks/use-key-up';
import useKeyUpOutside from 'src/hooks/use-key-up-outside';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import type { SelectProps } from './select.props';

import './select.scss';

function Select({
  className,
  desktopSize,
  isBorderless,
  isDisabled,
  isValid = true,
  items,
  label,
  labelledBy,
  size = 'regular',
  value,
  withSearch,
  onChange,
}: SelectProps) {
  const id = useId();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>('');
  const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);

  const filteredItems = items.filter((item) =>
    item.value.toLowerCase().includes(inputVal.toLowerCase().trim())
  );

  const selectRef = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(selectRef, () => setIsOpen(false), !isOpen);
  useKeyUpOutside(Key.ENTER, selectRef, () => setIsOpen(false), !isOpen);
  useKeyUp(Key.ESCAPE, () => setIsOpen(false), !isOpen);

  const handleClick = () => setIsOpen(!isOpen);

  const handleChange = (id: string) => {
    onChange(id);
    setIsOpen(false);
  };

  const handleSearchFocus = () => {
    setIsSearchFocus(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocus(false);
  };

  const handleClearButtonClick = () => {
    setInputVal('');
  };

  const handleSearchButtonClick = () => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setInputVal('');
      setIsSearchFocus(false);
    }
  }, [isOpen]);

  const currentValueLabel = items.find((item) => item.id === value)?.value;

  const selectClasses = classNames(
    'select',
    desktopSize && `select_desktop-${desktopSize}`,
    {
      select_open: isOpen,
      select_label: !!label,
      select_empty: !value,
      select_disabled: isDisabled,
      select_small: size === 'small',
      select_borderless: isBorderless,
    },
    className
  );

  const labelClasses = classNames('select__label', {
    label_placeholder: (!isOpen && !currentValueLabel) || !value,
  });

  const renderSearchIcon = () =>
    isSearchFocus ? (
      <IconButton
        icon={<Cross className="select__icon select__icon_clear" />}
        onClick={handleClearButtonClick}
        ariaLabel="clear search"
      />
    ) : (
      <IconButton
        icon={<Search className="select__icon select__icon_search" />}
        onClick={handleSearchButtonClick}
        ariaLabel="search"
      />
    );

  return (
    <div className={selectClasses} ref={selectRef}>
      <button
        className={classNames('select__button', !isValid && 'select__button_invalid')}
        aria-controls={`dropbox-${id}`}
        aria-expanded={isOpen}
        aria-labelledby={labelledBy}
        aria-haspopup="listbox"
        disabled={isDisabled}
        role="combobox"
        type="button"
        onClick={handleClick}
      >
        <span className="select__button-wrapper">
          {label ? <Label label={label} className={labelClasses} isSpan /> : null}
          <span dir="auto" className="select__value">
            {currentValueLabel}
          </span>
          <Arrow className="select__icon select__icon_arrow" role="presentation" />
        </span>
      </button>
      {isOpen && (
        <div className="select__dropbox scrollbar" id={`dropbox-${id}`} role="listbox">
          {withSearch && (
            <Input
              ref={searchRef}
              icon={renderSearchIcon()}
              value={inputVal}
              onChange={setInputVal}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              placeholder="SEARCH"
              className="select__dropbox-search"
            />
          )}

          <div className="select__dropbox-container">
            {filteredItems.map((item) => {
              const isActive = item.id === value;
              const classes = classNames('select__dropbox-item', {
                'select__dropbox-item_active': isActive,
                'select__dropbox-item_empty': !item.value,
              });
              return (
                <button
                  className={classes}
                  aria-selected={isActive}
                  dir="auto"
                  key={item.id}
                  role="option"
                  onClick={() => handleChange(item.id)}
                >
                  {item.value}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Select;
