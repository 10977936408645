import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import ExternalLink from 'src/components/common/external-link';

import { FOOTER_LINKS } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { FooterLinksProps } from './footer-links.props';

import './footer-links.scss';

const FooterLinks = observer(({ className, theme }: FooterLinksProps) => {
  const { getLocalization } = useLocalization();

  return (
    <ul
      className={classnames('footer-links', `footer-links_theme_${theme}`, className)}
      aria-label={getLocalization('Cemetery related links')}
    >
      {FOOTER_LINKS.map(({ title, url }) => (
        <li className="footer-links__item" key={title}>
          <ExternalLink className="footer-links__item-link" ariaLabel={title} href={url}>
            {getLocalization(title)}
          </ExternalLink>
        </li>
      ))}
    </ul>
  );
});

export default FooterLinks;
