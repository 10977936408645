import React from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import Label from 'src/components/ui/label';
import TextError from 'src/components/ui/text-error';

import {
  CYRILLIC_SYMBOLS_REG_EXP,
  LOCALIZATION_SECOND_WILDCARD,
  LOCALIZATION_WILDCARD,
} from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { InputProps } from './input.props';

import './input.scss';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      ariaLabel,
      autoComplete,
      desktopSize,
      hasCounter,
      icon,
      inputMode = 'text',
      isDisabled = false,
      isValid = true,
      label,
      mask = '',
      maskPlaceholder = '',
      maxLength,
      placeholder,
      rows,
      size = 'fullsize',
      textError = '',
      type = 'text',
      value,
      onBlur,
      onChange,
      onFocus,
      onKeyUp,
    },
    ref
  ) => {
    const { getLocalization } = useLocalization();
    const id = React.useId();
    const [isFocused, setIsFocused] = React.useState(false);

    const baseAriaLabel = ariaLabel || (label ? undefined : placeholder);
    const inputAriaLabel =
      baseAriaLabel &&
      (maxLength
        ? `${baseAriaLabel} (${getLocalization(
            `max ${LOCALIZATION_WILDCARD} characters`,
            `${maxLength}`
          )})`
        : baseAriaLabel);

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => {
      setIsFocused(false);
      onBlur?.(event);
    };

    const handleChange = (
      event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLTextAreaElement>
    ) => {
      const inputValue = event.currentTarget.value;
      const testedValue = inputValue.replace(CYRILLIC_SYMBOLS_REG_EXP, '');

      if (inputValue === testedValue) {
        onChange(testedValue);
      }
    };

    const renderInputMask = () => (
      <>
        <InputMask
          className="input__field"
          id={id}
          aria-label={inputAriaLabel}
          autoComplete={autoComplete}
          dir="auto"
          disabled={isDisabled}
          inputMode={inputMode}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          placeholder={placeholder}
          type="text"
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyUp={onKeyUp}
        />
        {icon}
      </>
    );

    const renderInput = () => {
      return rows ? (
        <textarea
          className="scrollbar input__textarea"
          id={id}
          aria-label={inputAriaLabel}
          autoComplete={autoComplete}
          dir="auto"
          disabled={isDisabled}
          inputMode={inputMode}
          maxLength={maxLength}
          placeholder={placeholder}
          rows={rows}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      ) : (
        <>
          {icon}
          <input
            className="input__field"
            id={id}
            aria-label={inputAriaLabel}
            autoComplete={autoComplete}
            dir="auto"
            disabled={isDisabled}
            inputMode={inputMode}
            maxLength={maxLength}
            placeholder={placeholder}
            type={type}
            ref={ref}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyUp={onKeyUp}
          />
        </>
      );
    };

    const inputClasses = classNames(
      'input',
      className,
      !rows && `input_${size}`,
      !rows && desktopSize && `input_desktop-${desktopSize}`,
      {
        input_label: !!label,
        input_mask: !!mask,
        input_disabled: isDisabled,
        input_focus: isFocused,
        input_icon: !!icon,
        input_multi: !!rows,
        input_invalid: !isValid && !isFocused,
        'input_with-counter': hasCounter,
      }
    );

    const labelClasses = classNames('input__label', {
      label_disabled: isDisabled,
      label_invalid: !isValid && !isFocused,
      label_placeholder: !value && !isFocused && !placeholder,
    });

    return (
      <div className={inputClasses}>
        {label && <Label id={id} label={label} className={labelClasses} />}
        {mask ? renderInputMask() : renderInput()}
        {hasCounter && (
          <div
            className="input__counter"
            aria-label={getLocalization(
              maxLength
                ? `${LOCALIZATION_WILDCARD} characters of ${LOCALIZATION_SECOND_WILDCARD}`
                : `${LOCALIZATION_WILDCARD} characters`,
              value.length.toString(),
              maxLength?.toString()
            )}
          >
            {value.length}
            {maxLength ? `/${maxLength}` : ''}
          </div>
        )}
        {!isValid && !isFocused && !isDisabled ? <TextError textError={textError} /> : null}
      </div>
    );
  }
);

export default React.memo(Input);
