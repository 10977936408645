import baseAxios, { AxiosRequestConfig } from 'axios';

const api = baseAxios.create({
  baseURL: `${window.API_URL}/`,
});

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const doGet = <T = any>(url: string, options?: AxiosRequestConfig) => {
  const filter = options?.params?.filter ? JSON.stringify(options?.params?.filter) : undefined;
  return api.get<T>(url, { ...options, params: { ...options?.params, filter } });
};

export const doPost = <T = any, P = any>(url: string, data?: P, options?: AxiosRequestConfig) =>
  api.post<T>(url, data, options);

export const doPatch = <T = any, P = any>(url: string, data?: P, options?: AxiosRequestConfig) =>
  api.patch<T>(url, data, options);

export const doPut = <T = any, P = any>(url: string, data: P, options?: AxiosRequestConfig) =>
  api.put<T>(url, data, options);

export const doDelete = <T = any>(url: string, options?: AxiosRequestConfig) =>
  api.delete<T>(url, options);
