import React from 'react';
import classNames from 'classnames';

import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross-icon.svg';

import type { ChipProps } from './chip.props';

import './chip.scss';

const Chip = ({ text, onClick, isWithClose, theme = 'outlined', size = 'regular' }: ChipProps) => {
  const handleChipClick = () => {
    if (!isWithClose) {
      onClick();
    }
  };

  const chipClasses = classNames(
    'chip',
    `chip_${theme}`,
    `chip_size-${size}`,
    isWithClose && `chip_with-close chip_with-close_${size}`
  );

  return (
    <div className={chipClasses} onClick={handleChipClick} tabIndex={isWithClose ? -1 : 0}>
      {text}
      {isWithClose && (
        <IconButton
          className="chip__close-button"
          icon={<CrossIcon className="chip__close-button-icon" />}
          onClick={onClick}
          ariaLabel="close"
        />
      )}
    </div>
  );
};

export default Chip;
