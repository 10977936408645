import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import Checkbox from 'src/components/ui/checkbox';

import popupStore from 'src/stores/popup-store';
import reservationStore from 'src/stores/reservation-store';

import { AppRoute, CHECKBOX_PRIVACY_URL, CHECKBOX_TERMS_URL, PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import useIsTablet from 'src/hooks/use-is-tablet';
import useToggle from 'src/hooks/use-toggle';
import type { ReservationButtonsProps } from './reservation-buttons.props';

import './reservation-buttons.scss';

const ReservationButtons = observer(
  ({ isConfirmationStage, toggleConfirmationStage }: ReservationButtonsProps) => {
    const navigate = useNavigate();
    const [isChecked, toggleIsChecked] = useToggle();
    const isTablet = useIsTablet();
    const { getLocalization } = useLocalization();

    const { isFormValid, sendRequest } = reservationStore;

    const checkboxLinks = useMemo(
      () => [
        {
          linkLabel: getLocalization('Terms of Use'),
          linkUrl: CHECKBOX_TERMS_URL,
        },
        {
          linkLabel: getLocalization('Privacy Policy'),
          linkUrl: CHECKBOX_PRIVACY_URL,
        },
      ],
      [getLocalization]
    );

    const handleCancelReservationButtonClick = useCallback(() => {
      popupStore.showPopup(PopupName.CANCEL_REQUEST, {
        mainActionHandler: () => navigate(AppRoute.MAP_VIEW_PAGE),
      });
    }, [navigate]);

    const handleConfirmButtonClick = useCallback(() => {
      void sendRequest(
        () => {
          popupStore.showPopup(PopupName.SUCCESS_REQUEST, {
            isReservation: true,
            onClose: () => navigate(AppRoute.MAP_VIEW_PAGE),
          });
        },
        () => popupStore.showPopup(PopupName.WARN)
      );
    }, [navigate, sendRequest]);

    return (
      <>
        {!isConfirmationStage && (
          <div className="reservation-buttons__checkbox-wrapper">
            <Checkbox
              id="reservationDetailsPageCheckbox"
              isChecked={isChecked}
              label={getLocalization('I agree with')}
              linkCollection={checkboxLinks}
              onChange={toggleIsChecked}
            />
          </div>
        )}

        <div className="reservation-buttons__buttons-wrapper">
          {isTablet ? (
            <>
              <Button
                label={
                  isConfirmationStage
                    ? getLocalization('Back to Edit')
                    : getLocalization('Cancel Reservation')
                }
                size="oversize"
                theme="outlined"
                onClick={
                  isConfirmationStage ? toggleConfirmationStage : handleCancelReservationButtonClick
                }
              />
              <Button
                isDisabled={!isChecked || !isFormValid}
                label={
                  isConfirmationStage
                    ? getLocalization('Confirm and Send Request')
                    : getLocalization('Proceed to Confirmation')
                }
                size="oversize"
                theme="filled"
                onClick={isConfirmationStage ? handleConfirmButtonClick : toggleConfirmationStage}
              />
            </>
          ) : (
            <>
              <Button
                isDisabled={!isChecked || !isFormValid}
                label={
                  isConfirmationStage
                    ? getLocalization('Confirm and Send Request')
                    : getLocalization('Proceed to Confirmation')
                }
                size="oversize"
                theme="filled"
                onClick={isConfirmationStage ? handleConfirmButtonClick : toggleConfirmationStage}
              />

              <Button
                label={
                  isConfirmationStage
                    ? getLocalization('Back to Edit')
                    : getLocalization('Cancel Reservation')
                }
                size="oversize"
                theme="outlined"
                onClick={
                  isConfirmationStage ? toggleConfirmationStage : handleCancelReservationButtonClick
                }
              />
            </>
          )}
        </div>
      </>
    );
  }
);

export default ReservationButtons;
