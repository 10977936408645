export enum PopupName {
  CANCEL_REQUEST = 'cancel-request',
  GALLERY = 'gallery',
  SUCCESS_REQUEST = 'success-request',
  WARN = 'warn',
  SHARE = 'share',
}

export const PopupReadableName = {
  [PopupName.CANCEL_REQUEST]: 'Cancel request',
  [PopupName.GALLERY]: 'Image gallery',
  [PopupName.SUCCESS_REQUEST]: 'Successful request',
  [PopupName.WARN]: 'Warning',
  [PopupName.SHARE]: 'Share',
};
