import axios from 'axios';

import type { IGoogleGeocode, IGoogleGeocodeAddress } from 'src/interfaces';

const GEO_CODING_URL = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

export async function getGeocodeAddress(
  address: string,
  onCallback: (value?: IGoogleGeocodeAddress) => void,
  onFail: () => void
) {
  if (!address) {
    return onCallback();
  }

  try {
    const {
      data: { results },
    } = await axios.get<IGoogleGeocode>(
      `${GEO_CODING_URL}${encodeURIComponent(address)}&key=${window.GOOGLE_MAP_API_KEY}`,
      { headers: { 'Accept-Language': 'en' } }
    );
    onCallback(results[0]);
  } catch (err: any) {
    onCallback();
    onFail();
    console.log(err);
  }
}
