import React from 'react';
import { observer } from 'mobx-react-lite';

import SectionPolygon from '../section-polygon';

import globalAppStore from 'src/stores/global-app-store';

import type { SectionsProps } from './sections.props';

const Sections = observer(({ onPolygonClick, showNumber, zoomLevel }: SectionsProps) => {
  const { sections = [] } = globalAppStore.cemetery || {};

  return (
    <>
      {sections.map((section) => (
        <SectionPolygon
          key={section.id}
          onPolygonClick={onPolygonClick}
          section={section}
          showNumber={showNumber}
          zoomLevel={zoomLevel}
        />
      ))}
    </>
  );
});

export default Sections;
