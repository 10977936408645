import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupCancelRequest = observer(({ handleCloseButtonClick }: ICommonPopupProps) => {
  const { mainActionHandler } = popupStore.getPopupState(PopupName.CANCEL_REQUEST);
  const { getLocalization } = useLocalization();

  const handleMainButtonClick = () => {
    mainActionHandler();

    handleCloseButtonClick();
  };

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{getLocalization('Cancel Reservation')}</p>

        <div className="popup__text-wrapper">
          <p className="popup__text">
            {getLocalization('Are you sure you want to cancel reservation?')}
          </p>

          <p className="popup__text">{getLocalization('Lot may be reserved by another person.')}</p>
        </div>
      </div>

      <div className="popup__buttons-wrapper">
        <Button
          className="popup__button"
          label={getLocalization('Cancel Reservation')}
          onClick={handleMainButtonClick}
          size="oversize"
          theme="filled"
        />

        <Button
          className="popup__button"
          label={getLocalization('Don’t Cancel')}
          onClick={handleCloseButtonClick}
          size="oversize"
          theme="outlined"
        />
      </div>
    </>
  );
});

export default PopupCancelRequest;
