import React from 'react';

import { useLocalization } from 'src/contexts/localization-context';
import { getLotDetails } from 'src/utils';
import type { LotDetailsProps } from './lot-details.props';

import './lot-details.scss';

const LotDetails = ({ lot }: LotDetailsProps) => {
  const { getLocalization } = useLocalization();
  const renderFirstField = () => {
    if (lot.mausoleum?.name) {
      return (
        <li className="lot-details__item lot-details__item_wide">
          <span className="lot-details__value">{getLocalization('Mausoleum')}:</span>
          <span className="lot-details__value">{lot.mausoleum.name}</span>
        </li>
      );
    }

    return (
      <li className="lot-details__item">
        <span className="lot-details__value">{getLocalization('Section')}:</span>
        <span className="lot-details__value">{lot.section}</span>
      </li>
    );
  };

  return (
    <ul className="lot-details">
      {renderFirstField()}

      {getLotDetails(lot).map(({ field, label }) => (
        <li key={label} className="lot-details__item">
          <span className="lot-details__value">{getLocalization(label)}:</span>
          <span className="lot-details__value">{getLocalization(field)}</span>
        </li>
      ))}
    </ul>
  );
};

export default LotDetails;
