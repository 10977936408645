import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import ExternalLink from 'src/components/common/external-link';
import CemeteryInfo from './components/cemetery-info';
import FooterLinks from './components/footer-links';
import Social from './components/social';
import { ReactComponent as AfsLogo } from 'src/assets/icons/afs-logo.svg';
import { ReactComponent as AfsLogoText1 } from 'src/assets/icons/afs-logo-text1.svg';

import { FooterTheme, isDesktopInnerPageTwoLinesFooterLinks, SOCIAL_LINKS } from 'src/constants';
import useIsDesktop from 'src/hooks/use-is-desktop';
import type { FooterProps } from './footer.props';

import './footer.scss';

const Footer = observer(({ className, theme }: FooterProps) => {
  const isDesktop = useIsDesktop();
  const isManySocialLinks = SOCIAL_LINKS.length > 2;
  const footerClasses = classnames('footer', `footer_${theme}`, className);

  const renderLinksInOrder = () => {
    const footerLinksProps = {
      theme,
      className: 'footer__organization-links',
    };
    const socialProps = {
      theme,
      className: 'footer__social-links',
    };

    if (isDesktop || isManySocialLinks) {
      return (
        <>
          <FooterLinks {...footerLinksProps} />
          <Social {...socialProps} />
        </>
      );
    } else {
      return (
        <>
          <Social {...socialProps} />
          <FooterLinks {...footerLinksProps} />
        </>
      );
    }
  };

  return (
    <footer className={footerClasses}>
      <div className="footer__container">
        {theme === FooterTheme.DARK && <CemeteryInfo className="footer__info-container" />}

        <div
          className={classnames(
            'footer__content',
            isManySocialLinks && 'footer__content_many',
            isDesktopInnerPageTwoLinesFooterLinks && 'footer__content_two-lines-desktop'
          )}
        >
          <ExternalLink
            className="footer__logo"
            ariaLabel="All Funeral Services site"
            href="https://allfuneral.com/"
          >
            <AfsLogo className="footer__logo-img" role="presentation" />
            <AfsLogoText1 className="footer__logo-text" role="presentation" />
          </ExternalLink>

          {renderLinksInOrder()}
        </div>
      </div>
    </footer>
  );
});

export default Footer;
