import { mapApiToSection } from 'src/adapters';
import { formatApiToPhone, getAddress, getCoords, getPathsCoords } from 'src/utils';
import type {
  IApiCemetery,
  IApiMausoleum,
  IApiSection,
  ICemetery,
  ILatLngCoords,
} from 'src/interfaces';

export const mapApiToCemeteryInfo = (
  {
    description,
    geometry,
    location,
    name,
    organization,
    schedule,
    tile_url,
    wms_url,
  }: IApiCemetery,
  sectionsList: IApiSection[] | null,
  mausoleumsList: IApiMausoleum[] | null
): ICemetery => ({
  address: getAddress(organization.location),
  cemeteryPaths: getPathsCoords(geometry) as ILatLngCoords[][],
  ...(location.point ? { coords: getCoords(location.point.coordinates) } : null),
  description,
  email: organization.email,
  location,
  mausoleums: mausoleumsList ?? [],
  name: name || '',
  phone: formatApiToPhone(organization.phone),
  schedule,
  sections: sectionsList?.map(mapApiToSection) ?? [],
  tileUrl: tile_url || '',
  wmsUrl: wms_url || '',
});
