import { useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Map from 'src/components/common/map';
import MapMarker from 'src/components/common/map-marker';

import MapStore from 'src/stores/map-store';

import { MAP_LOT_DEFAULT_ZOOM, MarkerType } from 'src/constants';
import type { LotMapProps } from './lot-map.props';

const LotMap = observer(
  ({
    className,
    cemetery,
    coords,
    height,
    width,
    isFullScreen,
    handleFullScreenButtonClick,
  }: LotMapProps) => {
    const [mapStore] = useState(
      () => new MapStore(coords, null, cemetery.tileUrl, cemetery.wmsUrl)
    );
    const { handleHomeButtonClick, onLoad } = mapStore;

    return (
      <Map
        className={classNames('lot-map', className)}
        center={coords}
        height={height}
        isFullScreen={isFullScreen}
        onLoad={onLoad}
        width={width}
        zoom={MAP_LOT_DEFAULT_ZOOM}
        handleHomeButtonClick={() => handleHomeButtonClick(MAP_LOT_DEFAULT_ZOOM)}
        handleFullScreenButtonClick={handleFullScreenButtonClick}
      >
        <MapMarker position={coords} type={MarkerType.PIN} />
      </Map>
    );
  }
);

export default LotMap;
