import { LogoTextLocation } from 'src/constants';

export const getCemeteryLogoProps = (
  location: LogoTextLocation,
  isTextSeparated: boolean,
  isColored?: boolean
) => {
  const smallLogoSrc =
    location === LogoTextLocation.SIDE || isColored
      ? '/logos/logo-small-colored.svg'
      : '/logos/logo-small-white.svg';
  const defaultLogoSrc =
    location === LogoTextLocation.SIDE ? '/logos/logo-medium.svg' : '/logos/logo-big.svg';
  const fullLogoSrc =
    location === LogoTextLocation.BOTTOM && isColored
      ? '/logos/logo-big-colored.svg'
      : defaultLogoSrc;

  const fullLogoWidth = location === LogoTextLocation.SIDE ? 150 : 200;
  const fullLogoHeight = location === LogoTextLocation.SIDE ? 34 : 200;

  const separatedLogoWidth = location === LogoTextLocation.SIDE ? 34 : 100;
  const separatedLogoHeight = location === LogoTextLocation.SIDE ? 34 : 110;

  const logoWidth = isTextSeparated ? separatedLogoWidth : fullLogoWidth;
  const logoHeight = isTextSeparated ? separatedLogoHeight : fullLogoHeight;

  const logoClasses = `cemetery-logo__img ${isTextSeparated ? 'cemetery-logo__img_with-text' : ''}`;
  const logoSrc = isTextSeparated ? smallLogoSrc : fullLogoSrc;

  return {
    logoClasses,
    logoSrc,
    logoWidth,
    logoHeight,
  };
};
