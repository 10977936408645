import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import CemeteryMap from 'src/components/common/cemetery-map';
import Header from 'src/components/common/header';
import SkipLink from 'src/components/ui/skip-link';
import MapFiltersWrapper from './components/map-filters-wrapper';
import MapLots from './components/map-lots';
import MapSort from './components/map-sort';

import filterStore from 'src/stores/filter-store';
import globalAppStore from 'src/stores/global-app-store';
import LotsViewStore from 'src/stores/lots-view-store';
import MapStore from 'src/stores/map-store';

import { MAP_DEFAULT_ZOOM } from 'src/constants';
import { useHeader } from 'src/contexts/header-context';
import { useLocalization } from 'src/contexts/localization-context';
import useIsLaptop from 'src/hooks/use-is-laptop';
import useWindowSize from 'src/hooks/use-window-size';

import './map-view-page.scss';

const FILTERS_DESKTOP_HEIGHT = 70;

const MapViewPage = observer(() => {
  const { clearStore, init } = filterStore;
  const [store] = useState(() => new LotsViewStore());
  const {
    lots,
    inGroundLots,
    mausoleumCoords,
    isLoading,
    selectedLot,
    resetSelectedLot,
    setSelectedLot,
  } = store;

  const isLaptop = useIsLaptop();
  const windowSize = useWindowSize();
  const { cemetery } = globalAppStore;
  const { getLocalization } = useLocalization();
  const { headerHeight } = useHeader();
  const mapHeight = `${windowSize.height - headerHeight - FILTERS_DESKTOP_HEIGHT}px`;
  const { cemeteryPaths = [], tileUrl = '', wmsUrl = '', coords } = cemetery || {};
  const [mapStore, setMapStore] = useState<MapStore | undefined>(undefined);

  const handleMapReset = useCallback(() => {
    mapStore?.handleHomeButtonClick(MAP_DEFAULT_ZOOM);
  }, [mapStore]);

  useEffect(() => {
    if (isLaptop && coords) {
      setMapStore(new MapStore(coords, cemeteryPaths, tileUrl, wmsUrl));
    } else {
      setMapStore(undefined);
    }
  }, [cemeteryPaths, coords, isLaptop, tileUrl, wmsUrl]);

  useEffect(() => {
    init();

    return () => clearStore();
  }, [clearStore, init]);

  return (
    <div className="map-view-page">
      <SkipLink href="#main" label="Skip to Lots List" position="fixed" />
      <Header actions="phone" isFullWidth />

      <MapFiltersWrapper onMapReset={handleMapReset} />

      <main className="map-view-page__content" id="main">
        <div className="map-view-page__list">
          <div className="map-view-page__list-wrapper">
            <h1 className="map-view-page__list-count">
              {`${getLocalization('Lots Found')}:`}{' '}
              <span className="map-view-page__list-count-value">{lots.length}</span>
            </h1>
            <MapSort className="map-view-page__sort" isDisabled={isLoading} />
          </div>
          <MapLots
            className="map-view-page__list-lots"
            handleCardClick={setSelectedLot}
            isLoading={isLoading}
            lots={lots}
            selectedLot={selectedLot}
          />
        </div>

        {isLaptop && coords && mapStore && (
          <CemeteryMap
            center={coords}
            cemeteryPaths={cemeteryPaths}
            height={mapHeight}
            width="100%"
            lots={inGroundLots}
            mapStore={mapStore}
            mausoleumsCoords={mausoleumCoords}
            resetSelectedLot={resetSelectedLot}
            selectedLot={selectedLot}
            handleLotMarkerClick={setSelectedLot}
          />
        )}
      </main>
    </div>
  );
});

export default MapViewPage;
