import React from 'react';
import classNames from 'classnames';

import type { LabelProps } from './label.props';

import './label.scss';

function Label({ className, id, isFloated, isSpan, label, theme, ...props }: LabelProps) {
  const classes = classNames('label', className, theme && `label_${theme}`, {
    label_floated: isFloated,
  });

  const tagElement = isSpan ? (
    <span className={classes} {...props}>
      {label}
    </span>
  ) : (
    <label className={classes} htmlFor={id} {...props}>
      {label}
    </label>
  );

  return label ? tagElement : null;
}

export default Label;
