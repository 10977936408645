import { CURRENCY_CENTS_COUNT, CURRENCY_SIGN, LOCALE_STRING } from 'src/constants';
import type { IRange } from 'src/interfaces';

export function formatCents(sum: number, withCents = false) {
  if (!sum) {
    return `${CURRENCY_SIGN}0`;
  }

  let moneySum = sum / CURRENCY_CENTS_COUNT;

  const options = withCents
    ? {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }
    : { maximumFractionDigits: 0 };
  return `${CURRENCY_SIGN}${moneySum.toLocaleString(LOCALE_STRING, options)}`;
}

export function formatCurrency(sum: number | string) {
  return sum === '' ? '' : formatCents(Number(sum) * 100);
}

export function formatPriceRange({ from, to }: IRange) {
  const startSum = formatCurrency(from);
  const endSum = formatCurrency(to);
  return `${CURRENCY_SIGN} ${startSum.slice(1)} - ${endSum.slice(1)}`;
}
