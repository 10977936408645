import { makeAutoObservable, reaction } from 'mobx';

import {
  MAP_CEMETERY_ZOOM,
  MAP_CLUSTER_ZOOM,
  MAP_DEFAULT_ZOOM,
  MAP_LOT_DEFAULT_ZOOM,
  MAP_MAX_ZOOM_OUT,
  MAP_OVERLAY_WIDTH,
  MAP_TILE_DEFAULT_SIZE,
  ZoomLevel,
} from 'src/constants';
import { xyzToWMSBounds } from 'src/utils';
import type { ILatLngCoords, ILot } from 'src/interfaces';

class MapStore {
  isFullScreen: boolean = false;
  map: google.maps.Map | null = null;
  mapCenter: ILatLngCoords; // default center to return
  mapZoom: number = MAP_DEFAULT_ZOOM;
  pathsToBoundOnLoad: ILatLngCoords[][] = [];
  selectedMarker: ILot | null = null;
  tileUrl: string = '';
  wmsUrl: string = '';

  constructor(
    center: ILatLngCoords,
    boundingPaths: ILatLngCoords[][] | null,
    tileUrl: string,
    wmsUrl: string
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.mapCenter = center;

    if (boundingPaths?.length) {
      this.pathsToBoundOnLoad = boundingPaths;
    }

    if (tileUrl) {
      this.tileUrl = tileUrl;
    }

    if (wmsUrl) {
      this.wmsUrl = wmsUrl;
    }

    reaction(
      () => this.selectedMarker,
      (marker) => {
        if (this.map && this.mapZoom < MAP_CLUSTER_ZOOM && marker) {
          this.map.setZoom(MAP_LOT_DEFAULT_ZOOM);
          this.moveCenter(marker.coords, MAP_OVERLAY_WIDTH / 2);
        }
      }
    );
  }

  setMap(map: google.maps.Map | null) {
    this.map = map;
  }

  setMapZoom(mapZoom: number) {
    this.mapZoom = mapZoom;
  }

  setSelectedMarker(lot: ILot | null) {
    this.selectedMarker = lot;
  }

  setIsFullScreen(isFullScreen: boolean) {
    this.isFullScreen = isFullScreen;
  }

  toggleFullScreen(cb: (isFullScreen: boolean) => void) {
    const newValue = !this.isFullScreen;
    this.isFullScreen = newValue;
    cb(newValue);
  }

  getMapWithLayers(map: google.maps.Map): google.maps.Map {
    if (this.tileUrl) {
      const tileMapType = new window.google.maps.ImageMapType({
        getTileUrl: (coord: google.maps.Point, zoom: number) =>
          this.tileUrl
            .replace('{x}', `${coord.x}`)
            .replace('{y}', `${coord.y}`)
            .replace('{z}', `${zoom}`),
        tileSize: new window.google.maps.Size(MAP_TILE_DEFAULT_SIZE, MAP_TILE_DEFAULT_SIZE),
      });
      map.overlayMapTypes.push(tileMapType);
      map.setMapTypeId('roadmap'); // increases zoom for map with tile
    }

    if (this.wmsUrl) {
      const wmsMapType = new window.google.maps.ImageMapType({
        getTileUrl: (coord: google.maps.Point, zoom: number) => {
          const bounds = xyzToWMSBounds(coord.x, coord.y, zoom);
          const bbox = bounds.join(',');
          return this.wmsUrl.replace('BBOX=', `BBOX=${bbox}`);
        },
        tileSize: new window.google.maps.Size(MAP_TILE_DEFAULT_SIZE, MAP_TILE_DEFAULT_SIZE),
        minZoom: MAP_MAX_ZOOM_OUT,
        maxZoom: MAP_LOT_DEFAULT_ZOOM,
      });
      map.overlayMapTypes.push(wmsMapType);
    }

    return map;
  }

  moveCenter(coords: ILatLngCoords, offsetX: number = 0, offsetY: number = 0) {
    if (this.map) {
      this.map.panTo(coords);

      if (offsetX || offsetY) {
        this.map.panBy(offsetX, offsetY);
      }
    }
  }

  fitBounds(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds();
    this.pathsToBoundOnLoad.map((pathCoords) => {
      if (Array.isArray(pathCoords)) {
        pathCoords.map((element) => {
          bounds.extend(element);
        });
      }
    });
    map.fitBounds(bounds);
  }

  onLoad(loadedMap: google.maps.Map) {
    const map = this.tileUrl || this.wmsUrl ? this.getMapWithLayers(loadedMap) : loadedMap;

    if (this.pathsToBoundOnLoad.length && !window.MAP_DEFAULT_ZOOM) {
      this.fitBounds(map);
    }
    this.setMap(map);
  }

  onZoom() {
    if (this.map) {
      const newZoom = this.map.getZoom();
      if (newZoom) {
        this.setMapZoom(newZoom);
      }
    }
  }

  handleHomeButtonClick(initialZoom = this.mapZoom) {
    if (!this.map) {
      return;
    }

    this.map.setCenter(this.mapCenter);

    this.map.setZoom(initialZoom);
    if (this.pathsToBoundOnLoad.length && !window.MAP_DEFAULT_ZOOM) {
      this.fitBounds(this.map);
    }
  }

  get zoomLevel() {
    if (this.mapZoom <= MAP_CEMETERY_ZOOM) {
      return ZoomLevel.CEMETERY;
    }

    if (this.mapZoom < MAP_CLUSTER_ZOOM && this.mapZoom > MAP_CEMETERY_ZOOM) {
      return ZoomLevel.SECTIONS;
    }

    return ZoomLevel.CLUSTERS;
  }

  get isZoomEnablesPopupShow() {
    return this.zoomLevel === ZoomLevel.CLUSTERS;
  }
}

export default MapStore;
