export enum FilterName {
  LOCATION = 'location',
  PRICE_RANGE = 'priceRange',
  RIGHTS = 'subTypesList',
  SORT = 'sort',
  TYPE = 'typesList',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
