import React from 'react';
import classNames from 'classnames';

import ThumbItem from 'src/components/common/image-viewer/components/thumb-item';

import { useLocalization } from 'src/contexts/localization-context';
import useDraggableScroll from 'src/hooks/use-draggable-scroll';
import useImagesListScroll from 'src/hooks/use-images-list-scroll';
import useResizeObserver from 'src/hooks/use-resize-observer';
import { getImagesAlt } from 'src/utils';
import type { ThumbsProps } from './thumbs.props';

import './thumbs.scss';

const Thumbs = React.forwardRef<HTMLDivElement, ThumbsProps>(
  ({ handleImageClick, images, imagesAlt, imageCurrentIndex }, ref) => {
    const {
      elementRef,
      listRef,
      isElementsFitContainerWidth,
      isElementsFitContainerHeight,
      updateStyleCalculation,
    } = useImagesListScroll(images, imageCurrentIndex);
    const { getLocalization } = useLocalization();
    useResizeObserver(listRef, updateStyleCalculation);
    const { onMouseDown } = useDraggableScroll(listRef, { direction: 'both' });

    const numberImages = images.length;

    return (
      <div className="thumbs" ref={ref}>
        <ul
          className={classNames(
            'thumbs__wrapper no-scrollbar',
            isElementsFitContainerWidth && 'thumbs__wrapper_centered_horizontal',
            isElementsFitContainerHeight && 'thumbs__wrapper_centered_vertical'
          )}
          ref={listRef}
          onMouseDown={onMouseDown}
        >
          {images.map((image, idx) => (
            <ThumbItem
              key={`thumb-${idx}`}
              handleImageClick={() => handleImageClick(idx)}
              image={image}
              imagesAlt={`${getImagesAlt(imagesAlt, idx, numberImages)} ${getLocalization(
                'preview'
              )}`}
              isActive={idx === imageCurrentIndex}
              ref={idx === 0 ? elementRef : null}
            />
          ))}
        </ul>
      </div>
    );
  }
);

export default Thumbs;
