import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import MainPage from 'src/pages/main-page';
import MapViewPage from 'src/pages/map-view-page';
import NotFoundPage from 'src/pages/not-found-page';
import Loader from 'src/components/common/loader';
import Popups from 'src/components/popups';

import globalAppStore from 'src/stores/global-app-store';

import { AppRoute, BASE_ROUTE } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import useGaTracker from 'src/hooks/use-ga-tracker';
import useVhValue from 'src/hooks/use-vh-value';
import { LotPageRoutes } from 'src/routes';

import 'src/scss/index.scss';

const App = observer(() => {
  useVhValue();
  useGaTracker();
  const { isLocalizationLoading } = useLocalization();

  const { isConfigCorrect, isConfigAndThemeLoaded, isLoadedTheme, isLoading, missingConfigVars } =
    globalAppStore;

  if (!isConfigAndThemeLoaded) {
    return (
      <div className="app app_err">
        {!isConfigCorrect && (
          <>
            <h2 className="app__error">Failed to load config params:</h2>
            <ul className="app__error-list">
              {missingConfigVars.map((item) => (
                <li key={item.name} className="app__error-list-item">
                  {item.name}
                </li>
              ))}
            </ul>
          </>
        )}
        {!isLoadedTheme && <h2 className="app__error">Failed to load theme assets</h2>}
      </div>
    );
  }

  if (isLoading || isLocalizationLoading) {
    return <Loader className="loader_position_fixed" />;
  }

  return (
    <div className="app">
      <Router basename={BASE_ROUTE}>
        <Routes>
          <Route path={AppRoute.MAIN_PAGE} element={<MainPage />} />
          <Route path={AppRoute.MAP_VIEW_PAGE} element={<MapViewPage />} />
          {LotPageRoutes()}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Popups />
      </Router>
    </div>
  );
});

export default App;
