import React from 'react';
import classNames from 'classnames';

import { useLocalization } from 'src/contexts/localization-context';
import type { ExternalLinkProps } from './external-link.props';

import './external-link.scss';

const ExternalLink = ({
  ariaLabel,
  children,
  className,
  href,
  isDecorated,
  target = '_blank',
  ...props
}: ExternalLinkProps) => {
  const { getLocalization } = useLocalization();
  const localizedAriaLabel = getLocalization(ariaLabel);

  return (
    <a
      aria-label={
        target === '_blank'
          ? `${localizedAriaLabel} (${getLocalization('opens in new window')})`
          : localizedAriaLabel
      }
      className={classNames('external-link', isDecorated && 'external-link_decorated', className)}
      href={href}
      rel="external noopener noreferrer"
      target={target}
      {...props}
    >
      {children}
    </a>
  );
};
export default ExternalLink;
