import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import ExternalLink from 'src/components/common/external-link';

import { SOCIAL_LINKS } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { SocialProps } from './social.props';

import './social.scss';

const Social = observer(({ className, theme }: SocialProps) => {
  const isManyLinks = SOCIAL_LINKS.length > 2;
  const { getLocalization } = useLocalization();

  return (
    <ul
      className={classnames(
        'social',
        `social_theme_${theme}`,
        className,
        isManyLinks && 'social_many'
      )}
      aria-label={getLocalization('Social media links')}
    >
      {SOCIAL_LINKS.map(({ url, logo: Logo, label }) => (
        <li key={label} className="social__item">
          <ExternalLink className="social__link" href={url || ''} ariaLabel={label}>
            <Logo className="social__icon" />
          </ExternalLink>
        </li>
      ))}
    </ul>
  );
});

export default Social;
