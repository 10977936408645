import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app';

import { GoogleMapsProvider } from 'src/contexts/google-maps-context';
import { HeaderProvider } from 'src/contexts/header-context';
import { LocalizationProvider } from 'src/contexts/localization-context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <GoogleMapsProvider>
    <HeaderProvider>
      <LocalizationProvider>
        <App />
      </LocalizationProvider>
    </HeaderProvider>
  </GoogleMapsProvider>
);
